import { createAutoAnimate } from "@formkit/auto-animate/solid";
import { A, useNavigate } from "@solidjs/router";
import { Accessor, JSX, Match, Setter, Show, Switch } from "solid-js";
import {
  giftCardIcon,
  logoutIcon,
  profileIcon,
  supportIcon,
} from "~/assets/assets";
import { logIntoHubble } from "~/screens/hubble_otp_auth/hubble_otp_auth";
import { Mode } from "~/types";
import { PhosphorIcon } from "~/widgets/icons";
import { AboutGiftCard } from "./about_gift_card";
import { isFreshLogin } from "./brand_l2/header";
import { useModal } from "./modal";
import { webEventManager } from "~/data/events";
import { openIntercom } from "~/utils/third_party/intercom";
import { BusinessMenu } from "./business_menu";
import { classNames } from "~/utils/etc";
import { Spacer } from "~/widgets/spacer";
import { DividerV2 } from "~/widgets/divider";
import { clientRepo } from "~/server/apis/client_repo";
import {
  profileSvgIcon,
  giftCardSvgIcon,
  helpAndSupportSvgIcon,
  termsAndConditionsSvgIcon,
  logoutSvgIcon,
  profileSvgIconDark,
  giftCardSvgIconDark,
  termsAndConditionsSvgIconDark,
  helpAndSupportSvgIconDark,
  logoutSvgIconDark,
} from "../assets/header_menu_svg_icons";

export function HeaderMenu(props: {
  setIsDropdownOpen: Setter<boolean>;
  isDropdownOpen: Accessor<boolean>;
  isLoggedIn: boolean;
  showBusinessMenu?: boolean;
  mode: Mode;
  isClientAxis?: () => boolean;
}) {
  const [parent] = createAutoAnimate();

  return (
    <div class="relative">
      <div
        style={{
          "box-shadow": "0px 3.6px 5.4px 0px rgba(219, 221, 222, 0.20)",
        }}
        id="header-menu"
        class="shadow hidden h-10 w-10 cursor-pointer items-center justify-center gap-[9px] rounded-[34.20px] border border-neutral-200 bg-baseTertiaryLight lg:inline-flex "
        onClick={() => {
          props.setIsDropdownOpen(!props.isDropdownOpen());
        }}
        tabIndex="0"
        onFocusOut={async () => {
          await new Promise((resolve) => setTimeout(resolve, 250));
          if (props.isDropdownOpen()) {
            props.setIsDropdownOpen(false);
          }
        }}
      >
        <img src={profileIcon} class="h-6" />
      </div>

      <div
        class={classNames(
          `fixed left-0 ${props.isClientAxis?.() ? "top-[4.5rem]" : "top-11"}  mt-2 flex w-full flex-col bg-baseTertiaryLight lg:top-[35px]`,
          `md:absolute md:-left-[150px] md:top-[10px] md:w-48 `
        )}
        classList={{
          "md:rounded-xl md:border md:border-neutral-200":
            props.isDropdownOpen(),
        }}
      >
        <div ref={parent}>
          <Show when={props.isDropdownOpen()}>
            <DropdownMenu />
          </Show>
        </div>
      </div>
    </div>
  );

  function DropdownMenu() {
    const navigate = useNavigate();

    const { setIsModalOn, updateModalContentGetter } = useModal()!;

    const isLoggedIn = isFreshLogin() ?? props.isLoggedIn;

    const handleLogout = async () => {
      await clientRepo.logout();
      navigate("/logout");
    };

    return (
      <div class=" flex flex-col p-2">
        <Show when={props.showBusinessMenu}>
          <div class="md:hidden">
            <div class="truncate-[0.44px] pl-2  text-[11px] font-medium text-textNormal">
              FOR BUSINESS
            </div>
            <Spacer height={8} />
            <BusinessMenu />
            <Spacer height={8} />
            <DividerV2 class="bg-baseTertiaryMedium" />
            <Spacer height={8} />
          </div>
        </Show>

        <Switch>
          <Match when={!isLoggedIn}>
            <Show when={props.mode != "rnr"}>
              <MenuItem
                title="Log in / Sign up"
                icon="user"
                iconUrl={profileIcon}
                iconBuilder={profileSvgIcon}
                darkIconBuilder={profileSvgIconDark}
                onClick={() => {
                  logIntoHubble(() => {
                    console.log(`setting sid cookie`);
                  }, webEventManager);
                }}
              />
            </Show>
          </Match>
          <Match when={true}>
            <MenuItem
              title="Profile "
              icon="user"
              iconUrl={profileIcon}
              iconBuilder={profileSvgIcon}
              darkIconBuilder={profileSvgIconDark}
              onClick={() => {
                navigate("/profile", {
                  resolve: true,
                });
              }}
            />
            <MenuItem
              title="My gift cards"
              icon="gift"
              iconUrl={giftCardIcon}
              iconBuilder={giftCardSvgIcon}
              darkIconBuilder={giftCardSvgIconDark}
              onClick={() => {
                navigate("/profile", {
                  resolve: true,
                });
              }}
            />
          </Match>
        </Switch>
        <Show when={props.mode != "rnr"}>
          <div class="lg:hidden">
            <MenuItem
              title="What’s a gift card?"
              icon="question"
              iconBuilder={giftCardSvgIcon}
              darkIconBuilder={giftCardSvgIconDark}
              onClick={() => {
                updateModalContentGetter(() => {
                  return <AboutGiftCard />;
                });

                setIsModalOn(true);
              }}
            />
          </div>
        </Show>
        <Show when={props.isClientAxis}>
          <A
            href="https://www.myhubble.money/axisbank/terms-and-conditions"
            target="_blank"
          >
            <MenuItem
              title="Terms & conditions"
              icon="headset"
              iconUrl={supportIcon}
              iconBuilder={termsAndConditionsSvgIcon}
              darkIconBuilder={termsAndConditionsSvgIconDark}
              onClick={() => {}}
            />
          </A>
        </Show>
        <MenuItem
          title="Help & support"
          icon="headset"
          iconUrl={supportIcon}
          iconBuilder={helpAndSupportSvgIcon}
          darkIconBuilder={helpAndSupportSvgIconDark}
          onClick={() => {
            openIntercom();
          }}
        />
        <Show when={isLoggedIn}>
          <MenuItem
            title="Logout"
            icon="SignOut"
            iconUrl={logoutIcon}
            iconBuilder={logoutSvgIcon}
            darkIconBuilder={logoutSvgIconDark}
            onClick={handleLogout}
          />
        </Show>
      </div>
    );
  }

  function MenuItem(props: {
    title: string;
    icon: string;
    iconUrl?: string;
    onClick: () => void;
    iconBuilder?: () => JSX.Element;
    darkIconBuilder?: () => JSX.Element;
  }) {
    return (
      <div
        class="inline-flex cursor-pointer flex-row items-center justify-start gap-1 self-stretch rounded-lg py-2 pl-1 pr-3 hover:bg-baseTertiaryMedium"
        onClick={props.onClick}
      >
        <div class=" h-[24px] w-[24px]  items-center justify-center  fill-black darkTheme:fill-white ">
          <Switch>
            <Match when={props.iconBuilder || props.darkIconBuilder}>
              <div class="block darkTheme:hidden">{props.iconBuilder?.()}</div>
              <div class="hidden darkTheme:block">
                {props.darkIconBuilder?.()}
              </div>
            </Match>
            <Match when={props.iconUrl}>
              <img src={props.iconUrl} />
            </Match>
            <Match when={true}>
              <PhosphorIcon
                name={props.icon}
                size="fill"
                fontSize={18}
                class="text-basePrimaryDark darkTheme:text-white"
              />
            </Match>
          </Switch>
        </div>
        <div class="flex w-full  text-subtitleSmall leading-[4px] text-textDark">
          {props.title}
        </div>
      </div>
    );
  }
}

import { Match, Switch, createSignal } from "solid-js";
import { getVoucherProduct } from "../../data/products";
import { VoucherCard } from "./voucher_card";
import { ButtonRegular } from "../../widgets/button";
import {
  VoucherCart,
  VoucherCartState,
  buildDefaultVoucherCart,
  refreshCart,
} from "./voucher";
import { NumberKeyboard } from "./number_keyboard";
import { VoucherDenomination } from "./denomination";
import { ProductVariantSelection } from "./variant";
import { HubbleRoute, pushPage } from "../../shared_states/modal";
import { ResourceLoader } from "../../widgets/resource_loader";
import { classNames } from "../../utils/etc";
import { VoucherProduct } from "~/server/types/brand";
import { useClassicContext } from "~/data/classic_context";

function getClassForState(state?: VoucherCartState) {
  switch (state) {
    case VoucherCartState.valid:
      return "text-successDark";
    case VoucherCartState.errored:
      return "text-errorDark";
    case VoucherCartState.processing:
      return "text-basePrimaryDark ";
    default:
      return "";
  }
}

export function VoucherGenEntry(props: {
  productId: string;
  onContinue: (cart: VoucherCart, voucher: VoucherProduct) => void;
  currentCart?: VoucherCart;
  voucherProduct?: VoucherProduct;
}) {
  const { data, isClientDonationType } = useClassicContext();

  const [cart, setCart] = createSignal<VoucherCart>(
    props.currentCart || {
      voucherType: "unknown",
      voucherProductId: props.productId,
      amount: 0,
    }
  );

  const [isFinishingUp, setIsFinishingUp] = createSignal(false);

  function refreshCartForChanges() {
    if (cart().voucherType != "unknown") {
      setCart({
        ...cart(),
        cartState: VoucherCartState.processing,
        cartMessage: "Verifying the changes...",
      });

      refreshCart(cart(), false, data.isLoggedIn).then((cart) => {
        setCart(cart);
      });
    }
  }

  return (
    <ResourceLoader
      loaderColor="#fff"
      fetch={async () =>
        props.voucherProduct || getVoucherProduct(props.productId)
      }
      onAfterFetch={(voucher) => {
        setCart(() => props.currentCart || buildDefaultVoucherCart(voucher));
        refreshCartForChanges();
      }}
    >
      {(voucher) => (
        <div class={`no-scrollbar max-[375px]:rounded-none`}>
          <div
            class={`no-scrollbar relative bottom-0 left-0 right-0 z-[1000] box-border flex min-h-[500px] w-full max-w-[575px] animate-[slideIn_0.3s_ease-in-out] flex-col items-stretch self-center overflow-x-hidden overflow-y-scroll [box-shadow:0_-10px_10px_0_rgba(0,_0,_0,_0.1)]`}
          >
            <div class="flex flex-col items-center overflow-hidden bg-white pt-[25px] max-[575px]:items-center max-[575px]:rounded-t-[20px] max-[375px]:items-center min-[576px]:items-center min-[576px]:rounded-t-[20px] ">
              <VoucherCard
                product={voucher}
                class="mx-5 box-border max-[575px]:min-w-[355px] max-[375px]:min-w-[auto] max-[375px]:self-stretch min-[576px]:min-w-[355px]"
                value={cart()}
                showCursor={true}
              />
              <Switch>
                <Match when={voucher.voucherProductType == "denominations"}>
                  <VoucherDenomination
                    class="box-border max-w-[355px] pt-5"
                    cart={cart()}
                    onChange={(denominations) => {
                      const count = denominations.reduce(
                        (acc, denomination) => acc + denomination.quantity,
                        0
                      );
                      const displayText =
                        (count > 0 ? count : "") +
                        " " +
                        (count > 1 ? "Cards" : "Card") +
                        " worth";
                      setCart({
                        ...cart(),
                        amount: denominations.reduce(
                          (acc, denomination) =>
                            acc +
                            denomination.denomination * denomination.quantity,
                          0
                        ),
                        denominations,
                        displayText,
                        coupons: undefined,
                      });
                      refreshCartForChanges();
                    }}
                  />
                </Match>
                <Match when={voucher.voucherProductType == "variant"}>
                  <ProductVariantSelection
                    class="box-border max-w-[355px] pb-12 pt-3"
                    cart={cart()}
                    onChange={(variant) => {
                      setCart({
                        ...cart(),
                        variantId: variant.variantId,
                        displayText: variant.description,
                        displayValue: variant.name,
                        amount: variant.amount,
                        coupons: undefined,
                      });

                      refreshCartForChanges();
                    }}
                  />
                </Match>
                <Match when={true}>
                  <NumberKeyboard
                    onChange={(value) => {
                      setCart({
                        ...cart(),
                        amount: value,
                        coupons: undefined,
                      });
                      refreshCartForChanges();
                    }}
                    value={cart().amount!}
                    class="box-border max-w-[355px] pt-3"
                  />
                </Match>
              </Switch>
            </div>
          </div>

          <div class="relative flex w-full justify-center self-center">
            <div class="absolute -top-[200px] box-border h-[200px] w-full bg-white [box-shadow:0_0_10px_white_inset]"></div>
            <div class="box-border flex w-full max-w-[575px] flex-col justify-center bg-white px-5 pb-5 pt-1">
              <div
                class={classNames(
                  "box-border min-h-6 w-full px-0 py-1.5 text-center text-f12 text-errorDark",
                  getClassForState(cart().cartState)
                )}
              >
                <span class="body-small w-full animate-[fadeIn_1s_ease-in]">
                  {cart().cartMessage}&nbsp;
                </span>
                {/*adding empty space above so that the modal doesn't move up and down*/}
              </div>

              <ButtonRegular
                onClick={async () => {
                  setIsFinishingUp(true);
                  await refreshCart(cart(), true, data.isLoggedIn);
                  setIsFinishingUp(false);
                  props.onContinue(cart(), voucher);
                }}
                class="button-filled box-border w-full max-w-[355px] self-center"
                isEnabled={cart().cartState == VoucherCartState.valid}
                isLoading={isFinishingUp()}
              >
                <Switch>
                  <Match when={isClientDonationType()}>Get this card</Match>
                  <Match when={true}>View discounted price</Match>
                </Switch>
              </ButtonRegular>
            </div>
          </div>
        </div>
      )}
    </ResourceLoader>
  );
}

export function openVoucherGen({
  onContinue,
  productId,
  currentCart,
  voucherProduct,
}: {
  productId: string;
  onContinue: (cart: VoucherCart, voucher: VoucherProduct) => void;
  currentCart?: VoucherCart;
  voucherProduct?: VoucherProduct;
}) {
  pushPage(
    () => (
      <VoucherGenEntry
        productId={productId}
        onContinue={onContinue}
        currentCart={currentCart}
        voucherProduct={voucherProduct}
      />
    ),
    { isModal: true },
    HubbleRoute.VOUCHER_EDIT_MODAL
  );
}

import { isServer } from "solid-js/web";

export const isMobileDevice = () => {
  if (isServer) {
    // isMobileDevice getting called in server context
    // Simply return false
    console.log(`isMobileDetect getting called in server, returning false`);
    return false;
  }

  const regex =
    /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

  return regex.test(navigator.userAgent);
};

export const isSafari = (): boolean => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

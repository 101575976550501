import { HubbleEvent, sdkEventManager } from "~/data/events";

export class APIError extends Error {
  constructor(
    public message: string,
    public code: number,
    public subErrors?: { field: string; message: string }[],
    public defaultMessage?: string
  ) {
    super(message);
  }

  getMostSpecificErrorMessage(): string {
    if (this.subErrors && this.subErrors.length > 0) {
      return this.subErrors[0].message;
    }

    if (this.defaultMessage) {
      return this.defaultMessage;
    }

    return this.message;
  }
}

export function hubbleFetch(
  url: string | URL,
  options?: RequestInit
): Promise<any> {
  return fetch(url, options)
    .then(async (res) => {
      if (!res.ok) {
        if (res.status === 401) {
          sdkEventManager.sendEvent(HubbleEvent.AUTH_ERROR, {
            error: "Invalid session or session has expired",
          });
          throw new APIError("Unauthorized", 401);
        }

        if (res.status >= 400) {
          /* Sample error response
          {
              "code": "INPUT_INVALID",
              "message": "Invalid input",
              "requestId": "3ggdlvel5c3",
              "debugMessage": "Validation failed for argument ...",
              "subErrors": [
                  {
                      "fieldName": "phoneNumber",
                      "message": "Invalid phone number"
                  }
              ]
          }
          */
          return res
            .json()
            .catch((error) => {
              throw new APIError(
                "Unknown error occurred. Please try again.",
                400
              );
            })
            .then((body: any) => {
              throw new APIError(body.message, 400, body.subErrors);
            });
        }

        throw new APIError(res.statusText, 400);
      }

      if (res.status === 204) {
        return;
      }
      return res.json();
    })
    .catch((error) => {
      throw error;
    });
}

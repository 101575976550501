import { For, Match, Show, Switch } from "solid-js";
import { getRedemptionTransactionDetail } from "../../data/products";
import {
  RefundStatusType,
  TransactionDetailV4Response,
} from "../../data/transaction";
import { classNames } from "../../utils/etc";
import { toRupees } from "../../utils/number";
import { BackButton, ThreeDotLoader } from "../../widgets/button";
import { ResourceLoader } from "../../widgets/resource_loader";
import { VoucherCardPlain } from "../voucher_edit/voucher_card";
import { PhosphorIcon } from "../../widgets/icons";
import { goBack, HubbleRoute, pushPage } from "../../shared_states/modal";
import { VoucherGenSuccess } from "../payment_options/voucher_gen_success";
import { openIntercom } from "~/utils/third_party/intercom";
import {
  copyText,
  getCoinLabelCapitalized,
  getCoinLabelCapitalizedPluralized,
} from "~/utils/common";
import { useClassicContext } from "~/data/classic_context";
import { Divider } from "~/widgets/divider";
import { defaultCoin } from "~/assets/assets";
import { Spacer } from "~/widgets/spacer";
import { DonationDetails, getDonationDetails } from "~/data/donation";

export function TransactionDetail(props: { transactionId: string }) {
  const { isClientDonationType } = useClassicContext();
  const getTransactionDetailData = async () => {
    const transactionDetail = await getRedemptionTransactionDetail(
      props.transactionId
    );
    if (isClientDonationType()) {
      const donationDetails = await getDonationDetails(props.transactionId);
      return {
        transactionDetail,
        donationDetails,
      };
    }
    return {
      transactionDetail,
    };
  };

  return (
    <ResourceLoader fetch={() => getTransactionDetailData()}>
      {(detail) => {
        const transactionDetail = detail.transactionDetail;
        return (
          <div
            class={`no-scrollbar absolute bottom-0 left-0 right-0 top-0 flex flex-col items-stretch gap-4 overflow-x-hidden overflow-y-scroll bg-baseTertiaryMedium p-5  pt-[60px]`}
          >
            <BackButton />
            <div class="flex w-full max-w-[375px] flex-col items-stretch gap-4">
              <Show when={transactionDetail.orderStatus == "COMPLETED"}>
                <p class="title-appbar text-center font-semibold text-baseDark">
                  Tap card to view card details
                </p>
              </Show>
              <VoucherCardPlain
                backgroundColor={
                  transactionDetail.brandDetails.cardBackgroundColor
                }
                displayText={"Card worth"}
                plainLogoUrl={transactionDetail.brandDetails.plainLogoUrl}
                value={toRupees(transactionDetail.amount)}
                class="z-[1] w-full self-center md:w-auto md:min-w-[375px]"
                onClick={() => {
                  if (transactionDetail.orderStatus == "COMPLETED") {
                    pushPage(
                      () => (
                        <VoucherGenSuccess
                          orderId={transactionDetail.orderId}
                          transactionId={props.transactionId}
                          onClose={() => goBack()}
                          refreshProfile={false}
                        />
                      ),
                      undefined,
                      HubbleRoute.VOUCHER_GENERATION_SUCCESS
                    );
                  }
                }}
              />

              <Show
                when={
                  transactionDetail.refundStatus === "SUCCESS" ||
                  transactionDetail.refundStatus === "INITIATED" ||
                  transactionDetail.refundStatus === "PENDING"
                }
              >
                <RefundStatus
                  status={transactionDetail.refundStatus}
                  amount={transactionDetail.amountBreakup.paidAmount}
                  timestampStr={transactionDetail.refundStatusLastUpdatedAt}
                />
              </Show>
              <ItemDetailCard transaction={transactionDetail} />
              <DonationDetailCard
                transaction={transactionDetail}
                donationDetails={detail.donationDetails}
              />
              <BillDetailsCard transaction={transactionDetail} />
              <OrderDetailsCard transaction={transactionDetail} />
              <InvoiceCard transaction={transactionDetail} />
            </div>
          </div>
        );
      }}
    </ResourceLoader>
  );
}

function ItemDetailCard(props: { transaction: TransactionDetailV4Response }) {
  // const showSeeAllButton = props.transaction.orderStatus == "COMPLETED";
  const showLoader =
    props.transaction.orderStatus == "INITIALISED" ||
    props.transaction.orderStatus == "PROCESSING";

  return (
    <div class="flex flex-col items-stretch gap-4 rounded-2xl bg-white p-5 [box-shadow:0px_4px_4px_0px_rgba(33,_36,_39,_0.02)]">
      <span class="title-appbar flex items-center justify-between border-b border-baseTertiaryMedium pb-4 text-bold text-textDark">
        Item details
        <Show when={showLoader}>
          <ThreeDotLoader color="#000" />
        </Show>
      </span>
      <div class="flex flex-col gap-4">
        <div
          class={classNames(
            "body-small flex items-center justify-between text-mediumBold text-textDark",
            "text-f12 text-textNormal"
          )}
        >
          <span>Item</span>
          <span>Quantity</span>
        </div>
        <For each={props.transaction.denominationDetails}>
          {(denomination) => (
            <div
              class={
                "body-small flex items-center justify-between text-mediumBold text-textDark"
              }
            >
              <span>
                {toRupees(denomination.denomination)}{" "}
                {props.transaction.brandDetails.brandName} card
              </span>
              <span>{denomination.quantity}</span>
            </div>
          )}
        </For>
      </div>
    </div>
  );
}

function DonationDetailCard(props: {
  transaction: TransactionDetailV4Response;
  donationDetails?: DonationDetails;
}) {
  return (
    <div>
      <Show when={props.donationDetails}>
        <div class="flex flex-col gap-3 rounded-2xl border bg-white p-5">
          <div class="text-bold">Donation details</div>
          <Divider />
          <div class="flex justify-between text-mediumBold text-successDark">
            <div>
              Total donation (
              {
                props.transaction.amountBreakup.discountDetails.hubbleDiscount
                  .percentage
              }
              %)
            </div>
            <div>
              {toRupees(
                props.transaction.amountBreakup.discountDetails.hubbleDiscount
                  .amount
              )}
            </div>
          </div>
          <div class="text-medium">{props.donationDetails?.name}</div>
        </div>
      </Show>
    </div>
  );
}

function BillDetailsCard(props: { transaction: TransactionDetailV4Response }) {
  return (
    <div class="flex flex-col items-stretch rounded-2xl bg-white p-5 [box-shadow:0px_4px_4px_0px_rgba(33,_36,_39,_0.02)]">
      <span class="title-appbar flex items-center justify-between border-b border-baseTertiaryMedium pb-4 text-bold text-textDark">
        Bill details
      </span>
      <div>
        <div class="title-secondary flex flex-row justify-between px-0 py-3 text-mediumBold text-textDark">
          <span>Brand card worth</span>
          <span>{toRupees(props.transaction.amount)}</span>
        </div>
        <Show when={props.transaction.useCoinsToFundBaseDiscount}>
          <CoinsWhenUseCoinsToFundBaseDiscount
            transaction={props.transaction}
          />
        </Show>
        <Show
          when={
            props.transaction.amountBreakup.discountDetails.couponDiscount
              .amount > 0
          }
        >
          <div class="flex flex-row justify-between px-0 py-3 text-mediumBold text-textDark">
            <span>
              Coupon discount (
              {props.transaction.amountBreakup.discountDetails.code})
            </span>
            <span>
              {"-" +
                toRupees(
                  props.transaction.amountBreakup.discountDetails.couponDiscount
                    .amount
                )}
            </span>
          </div>
        </Show>
        <Divider />
        <div class="flex items-center justify-between pt-3 text-bold text-textDark">
          <span class="title-secondary flex flex-col gap-1 text-mediumSemiBold text-textDark">
            You paid
            <span class="text-f12 text-textNormal">
              {props.transaction.paymentMode}
            </span>
          </span>
          <span class="title-secondary">
            {toRupees(props.transaction.amountBreakup.paidAmount)}
          </span>
        </div>
      </div>
    </div>
  );
}

type CoinsWhenUseCoinsToFundBaseDiscountProps = {
  transaction: TransactionDetailV4Response;
};

function CoinsWhenUseCoinsToFundBaseDiscount(
  props: CoinsWhenUseCoinsToFundBaseDiscountProps
) {
  const { data } = useClassicContext();
  const clientId =
    data.isLoggedIn && data.mode == "sdk" ? data.initParams.clientId : "hubble";
  const coinIconUrl = data.isLoggedIn
    ? data.clientConfig.coinIconUrl
    : defaultCoin;

  const coinToInrConversionRatio =
    props.transaction.coinToInrConversionRatio ?? 1;

  const coinLabel = getCoinLabelCapitalized(clientId);
  const coinLabelPluralized = getCoinLabelCapitalizedPluralized(clientId);

  const discountAmount =
    props.transaction.amountBreakup.discountDetails.hubbleDiscount.amount;
  const coinsUsed =
    props.transaction.coinsDebited ?? discountAmount / coinToInrConversionRatio;
  return (
    <div class="flex flex-col py-2">
      <div class="space-between flex flex-row justify-between py-1 text-mediumBolder text-successDark">
        <span class="flex flex-row items-center gap-1">
          {coinLabel} discount (
          <img src={coinIconUrl} class="h-4 w-4" alt="coin icon" /> {coinsUsed})
        </span>
        <span>-{toRupees(discountAmount)}</span>
      </div>

      <div class="flex flex-row items-center gap-1 text-medium text-textNormal">
        <img src={coinIconUrl} class="h-4 w-4" alt="coin icon" />{" "}
        {1 / coinToInrConversionRatio} {coinLabelPluralized} = ₹1
      </div>
    </div>
  );
}

function OrderDetailsCard(props: { transaction: TransactionDetailV4Response }) {
  return (
    <div class="flex flex-col items-stretch gap-3 rounded-2xl bg-white p-5 [box-shadow:0px_4px_4px_0px_rgba(33,_36,_39,_0.02)]">
      <span class="title-appbar flex items-center justify-between border-b border-baseTertiaryMedium pb-4 text-bold text-textDark">
        Order details
      </span>
      <div class="flex flex-col gap-[14px]">
        <div
          class="flex flex-col items-stretch justify-between gap-1 text-medium text-textNormal"
          onClick={async () => {
            await copyText({
              text: props.transaction.orderId,
            });
          }}
        >
          <span class="title-secondary text-mediumSemiBold text-textDark">
            Order ID
          </span>
          <span class="body-small flex items-center justify-between text-[13px]">
            {props.transaction.orderId} <PhosphorIcon name="copy-simple" />
          </span>
        </div>
        <div
          class="flex flex-col items-stretch justify-between gap-1 text-medium text-textNormal"
          onClick={async () => {
            await copyText({
              text: props.transaction.id,
            });
          }}
        >
          <span class="title-secondary text-mediumSemiBold text-textDark">
            Transaction ID
          </span>
          <span class="body-small flex items-center justify-between text-[13px]">
            {props.transaction.id} <PhosphorIcon name="copy-simple" />
          </span>
        </div>
        <div class="flex flex-col items-stretch justify-between gap-1 text-medium text-textNormal">
          <span class="title-secondary text-mediumSemiBold text-textDark">
            Purchase date
          </span>
          <span class="body-small flex items-center justify-between text-[13px]">
            {formatDate(new Date(props.transaction.dateTime))}
          </span>
        </div>
      </div>
    </div>
  );
}

function InvoiceCard(props: { transaction: TransactionDetailV4Response }) {
  return (
    <div class="flex flex-col items-stretch rounded-2xl bg-white px-5 py-0 [box-shadow:0px_4px_4px_0px_rgba(33,_36,_39,_0.02)]">
      <div
        class="flex cursor-pointer gap-1.5 border-t border-baseTertiaryMedium px-0 py-4 first:border-t-0"
        onClick={() => {
          pushPage(
            () => <InvoicePage url={props.transaction.receiptLink} />,
            {
              isModal: true,
            },
            HubbleRoute.INVOICE
          );
        }}
      >
        <PhosphorIcon name="file-text" />
        <span class="title-secondary flex items-center justify-between text-[13px]">
          View invoice
        </span>
      </div>

      <div
        class="flex cursor-pointer gap-1.5 border-t border-baseTertiaryMedium px-0 py-4 first:border-t-0"
        onClick={openIntercom}
      >
        <PhosphorIcon name="chat-dots" />
        <span class="title-secondary flex items-center justify-between text-[13px]">
          Contact support
        </span>
      </div>
    </div>
  );
}

function InvoicePage(props: { url: string }) {
  return (
    <div class="rounded-bl-0 rounded-br-0 h-[80vh] rounded-tl-2xl rounded-tr-2xl bg-white">
      <iframe class="h-full w-full border-none" src={props.url} />
    </div>
  );
}

/// format date to 12 Jun 2023 · 4:00 PM
function formatDate(date: Date) {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
}

type RefundSuccessfulProps = {
  amount: number;
  timestampStr: string;
  status: RefundStatusType;
};

function RefundStatus(props: RefundSuccessfulProps) {
  const formattedDateTime = formatDate(new Date(props.timestampStr));

  return (
    <div class="flex flex-col rounded-2xl bg-baseTertiaryLight p-5">
      <div class="flex flex-row justify-between">
        <div class="inline-flex flex-row items-center gap-1">
          <Switch>
            <Match when={props.status == "SUCCESS"}>
              <PhosphorIcon
                class="text-successDark"
                name="check"
                size="bold"
                fontSize={13}
              />

              <span class="text-mediumBold text-successDark">
                Refund successful
              </span>
            </Match>
            <Match when={true}>
              <span class="text-mediumBold text-successDark">
                Refund initiated
              </span>
            </Match>
          </Switch>
        </div>

        <div class="body-small text-f12 text-textNormal">
          {formattedDateTime}
        </div>
      </div>

      <Spacer height={16} />
      <Divider />
      <Spacer height={12} />
      <div class="text-medium text-textDark">
        <Switch>
          <Match when={props.status == "SUCCESS"}>
            ₹{props.amount} credit back to your source account
          </Match>
          <Match when={true}>
            Takes around 3-5 business days for refund amount to credit back to
            your source account
          </Match>
        </Switch>
      </div>
    </div>
  );
}

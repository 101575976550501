import { pages, popPage, popWithAnimation } from "~/shared_states/modal";
import { closeSearchPage, isSearchVisible } from "~/screens/home/search";
import { routerLog } from "./logging";
import {
  closeIntercom,
  isIntercomVisible,
  setIsIntercomVisible,
} from "~/utils/third_party/intercom";
import { HubbleEvent, sdkEventManager } from "~/data/events";

export default function attachPopStateListener() {
  routerLog("Attached pop state listener");
  window.onpopstate = popStateListener;
}

export function detachPopStateListener() {
  window.onpopstate = null;
  routerLog(`Popstate listener detached`);
}

export function postCloseEventToAllExternalListeners() {
  // pass close event to parent iframe
  // not relevant in Hubble mode
  routerLog(`Posting a message for parent iframe to close`);
  window.parent.postMessage(
    {
      type: "close",
    },
    "*"
  );

  try {
    routerLog(`Sending event to RN listener`);
    // @ts-ignore
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "action",
        action: "close",
      })
    );
  } catch (e) {
    routerLog(`Sending event to RN failed`);
  }

  console.log("Calling close on AndroidHost if available in scope");
  (window as any).AndroidHost &&
    typeof (window as any).AndroidHost.close === "function" &&
    (window as any).AndroidHost.close();

  try {
    (window as any).webkit?.messageHandlers?.bridge?.postMessage("close");
  } catch (e) {}
}

export async function popStateListener() {
  routerLog("popStateListener called");
  routerLog(`Pages are ${pages().map((p) => p.name)}`);

  if (isIntercomVisible()) {
    setIsIntercomVisible(false);
    closeIntercom();
    return;
  }

  if (pages().length > 1) {
    popWithAnimation();
  } else {
    routerLog(`Last page`);
    if (isSearchVisible()) {
      routerLog(`Search visible. Closing search`);
      closeSearchPage();
    } else {
      sdkEventManager.sendEvent(HubbleEvent.EXITED, {});
      postCloseEventToAllExternalListeners();
      popPage();
    }
  }
}

import { Match, Show, Switch } from "solid-js";
import { HubbleFullSvg } from "../../assets/assets";
import { classNames, getHexColorBrightness } from "../../utils/etc";
import { VoucherCart } from "./voucher";
import { VoucherProduct } from "~/server/types/brand";

export function VoucherCard(props: {
  product: VoucherProduct;
  class?: string;
  value: VoucherCart;
  showEditButton?: boolean;
  showCursor?: boolean;
  onEdit?: () => void;
}) {
  const backgroundColor =
    props.product.voucherProductMetadata.cardBackgroundColor;

  const textColor =
    getHexColorBrightness(backgroundColor) > 170 ? "black" : "white";

  return (
    <div
      class={classNames(
        "relative flex h-[207px] min-h-[207px] items-center justify-center rounded-3xl bg-[#e23744] [box-shadow:0px_2px_8px_rgba(0,_0,_0,_0.1)_,_0px_0px_36px_8px_#fff]",
        props.class
      )}
      style={{
        "background-color":
          props.product.voucherProductMetadata.cardBackgroundColor,
      }}
    >
      <div class="absolute -top-[45px] right-[60px] h-[294.596px] w-[37.114px] flex-shrink-0 rotate-[38.138deg] bg-[rgba(255,_255,_255,_0.08)] blur-[15px] filter"></div>

      <div class="absolute bottom-5 left-5">
        <img
          src={props.product.voucherProductMetadata.plainLogoUrl}
          class="h-10"
          alt={props.product.voucherProductMetadata.title}
        />
        <div class="flex gap-1">
          <DotSvg />
          <DotSvg />
          <DotSvg />
          <DotSvg />
          <DotSvg />
          <DotSvg />
        </div>
      </div>

      <div class="absolute bottom-5 right-5 outline-none">
        <HubbleFullSvg class="h-4 fill-[#ffffff30]" />
      </div>

      <div
        class="relative flex flex-col items-center outline-none"
        style={{ color: textColor }}
      >
        <span class="title-secondary text-center text-medium">
          {props.value.displayText}
        </span>
        <div
          class="title-display flex flex-row items-center gap-1 border-none bg-none text-center text-h2 outline-none"
          onClick={props.showEditButton ? props.onEdit : undefined}
        >
          <Switch>
            <Match when={props.value.voucherType == "denominations"}>
              <span>{props.value.amount}</span>
            </Match>

            <Match when={props.value.voucherType == "variant"}>
              <span>{props.value.displayValue}</span>
            </Match>

            <Match when={true}>
              <span>{props.value.amount}</span>
              <Show when={props.showCursor}>
                <span class="animateBlink h-10 w-0.5 bg-baseTertiaryLight"></span>
              </Show>
            </Match>
          </Switch>

          <Show when={props.showEditButton}>
            <i
              aria-label="edit amount"
              class="ph ph-pencil-simple text-[24px]"
            ></i>
          </Show>
        </div>
      </div>
    </div>
  );
}

export function VoucherCardPlain(props: {
  backgroundColor: string;
  class?: string;
  plainLogoUrl: string;
  displayText: string;
  value: string;
  onClick?: () => void;
}) {
  const backgroundColor = props.backgroundColor;

  const textColor =
    getHexColorBrightness(backgroundColor) > 170 ? "black" : "white";

  return (
    <div
      class={classNames(
        "relative flex h-[207px] min-h-[207px] items-center justify-center rounded-3xl bg-[#e23744] [box-shadow:0px_2px_8px_rgba(0,_0,_0,_0.1)_,_0px_0px_36px_8px_#fff]",
        props.class
      )}
      style={{ "background-color": backgroundColor }}
      onClick={props.onClick}
    >
      <div class="absolute -top-[45px] right-[60px] h-[294.596px] w-[37.114px] flex-shrink-0 rotate-[38.138deg] bg-[#ffffff14] blur-[15px] filter"></div>

      <div class="absolute bottom-5 left-5">
        <img src={props.plainLogoUrl} class="h-10" />
        <div class="flex gap-1">
          <DotSvg />
          <DotSvg />
          <DotSvg />
          <DotSvg />
          <DotSvg />
          <DotSvg />
        </div>
      </div>

      <div class="absolute bottom-5 right-5 outline-none">
        <HubbleFullSvg class="h-4 fill-[#ffffff30]" />
      </div>

      <div
        class="relative flex flex-col items-center outline-none"
        style={{ color: textColor }}
      >
        <span class="title-secondary text-center text-medium">
          {props.displayText}
        </span>
        <div class="title-display flex flex-row items-center gap-1 border-none bg-none text-center text-h2 outline-none">
          <span>{props.value}</span>
        </div>
      </div>
    </div>
  );
}

function DotSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5"
      height="5"
      viewBox="0 0 5 5"
      fill="#e7e7e7"
    >
      <circle cx="2.5" cy="2.5" r="2.5" fill-opacity="0.5" />
    </svg>
  );
}

import { createAutoAnimate } from "@formkit/auto-animate/solid";
import { Accessor, For, Setter, Show } from "solid-js";
import { cartIcon, cupIcon, profileIcon, rupeeIconFill } from "~/assets/assets";
import ArrowDown from "~/assets/svg_icons/arrow_down";
import ArrowUpRight from "~/assets/svg_icons/arrow_up_right";
import { PhosphorIcon } from "~/widgets/icons";
import { Spacer } from "~/widgets/spacer";

export function BusinessMenuCta(props: {
  setIsDropdownOpen: Setter<boolean>;
  isDropdownOpen: Accessor<boolean>;
}) {
  const [parent, setEnabled] = createAutoAnimate();
  return (
    <div
      class="relative"
      onMouseEnter={() => {
        props.setIsDropdownOpen(true);
      }}
      onMouseLeave={() => {
        props.setIsDropdownOpen(false);
      }}
    >
      <div
        class="flex cursor-pointer flex-row items-center justify-center gap-1  p-3"
        onClick={() => {
          props.setIsDropdownOpen(!props.isDropdownOpen());
        }}
        tabIndex={1}
        onFocusOut={async () => {
          await new Promise((resolve) => setTimeout(resolve, 250));
          if (props.isDropdownOpen()) {
            props.setIsDropdownOpen(false);
          }
        }}
      >
        <p class=" text-[13px] font-semibold tracking-[-0.13px]">
          For business
        </p>
        <PhosphorIcon
          name={props.isDropdownOpen() ? "caret-up" : "caret-down"}
          fontSize={14}
          class="font-semibold"
          size="bold"
        ></PhosphorIcon>
      </div>

      <div
        class={`absolute -left-[120px] top-[10px] mt-2 flex  w-[270px] flex-col bg-baseTertiaryLight md:-left-[175px] lg:top-[38px] ${props.isDropdownOpen() ? "rounded-xl border border-neutral-200 p-1 " : ""}`}
      >
        <div ref={parent}>
          <Show when={props.isDropdownOpen()}>
            <BusinessMenu />
          </Show>
        </div>
      </div>
    </div>
  );
}

export function BusinessMenu() {
  type BusinessCtaProps = {
    image: string;
    title: string;
    description: string;
    link: string;
  };

  const businessCtas: BusinessCtaProps[] = [
    {
      image: rupeeIconFill,
      title: "Rewards store for consumer apps",
      description: "Drive engagement & loyalty via an in-app reward store",
      link: "https://universe.myhubble.money/rewards-store",
    },
    {
      image: cupIcon,
      title: "Employee rewards",
      description: "Gift your employees a unique rewarding experience",
      link: "https://universe.myhubble.money/employee-rewards",
    },
    {
      image: cartIcon,
      title: "Business orders",
      description: "Buy gift cards for business use",
      link: "https://business.myhubble.money/corporate-gift-cards",
    },
  ];

  return (
    <div class="flex flex-col  ">
      <For each={businessCtas}>
        {(cta) => (
          <Item
            image={cta.image}
            title={cta.title}
            description={cta.description}
            link={cta.link}
          />
        )}
      </For>
    </div>
  );

  function Item(props: BusinessCtaProps) {
    return (
      <div
        class="group flex cursor-pointer flex-row items-start rounded-lg  py-2 pl-1 pr-2 transition-colors duration-100 hover:bg-baseTertiaryMedium md:pl-3"
        onClick={() => {
          window.open(props.link, "_blank");
        }}
      >
        <img src={props.image} class="h-6 w-6"></img>
        <Spacer width={8} />
        <div class="flex flex-1 grow flex-col">
          <div class=" text-[13px] font-semibold tracking-[-0.13px] text-textDark ">
            {props.title}
          </div>
          <Spacer height={2} />
          <div class=" text-[12px] font-normal tracking-[-0.12px] text-textNormal ">
            {props.description}
          </div>
        </div>
        <Spacer width={8} />
        <div
          class="opacity-0 transition-opacity duration-100 group-hover:opacity-100"
          classList={{
            "group-hover:opacity-100": true,
          }}
        >
          <ArrowUpRight />
        </div>
      </div>
    );
  }
}

import { EventName } from "~/data/events";
import { eventsLog } from "../logging";

export function hostOnAnalyticsEvent(event: EventName, properties?: object) {
  eventsLog(`Sending event ${event} to host`);

  try {
    if (
      (window as any).AndroidHost &&
      typeof (window as any).AndroidHost.onAnalyticsEvent === "function"
    ) {
      (window as any).AndroidHost.onAnalyticsEvent(
        event,
        JSON.stringify(properties || {})
      );
      eventsLog(`${event} sent to Android`);
    } else {
      eventsLog(`No listener on Android. Skipping`);
    }
  } catch (e) {
    eventsLog(`Error while sending ${event} to Android - ${e}`);
  }

  // Add same for iOS,
  try {
    if ((window as any).webkit?.messageHandlers?.bridge) {
      (window as any).webkit?.messageHandlers?.bridge?.postMessage(
        JSON.stringify({
          event,
          properties,
        })
      );
      eventsLog(`${event} sent to iOS`);
    } else {
      eventsLog(`No listener on iOS. Skipping`);
    }
  } catch (e) {
    eventsLog(`Error while sending ${event} to iOS - ${e}`);
  }

  // post a message to window
  try {
    // @ts-ignore
    if (window.ReactNativeWebView) {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: "analytics",
          event,
          properties,
        })
      );
      eventsLog(`${event} sent to RN`);
    } else {
      eventsLog(`No listener on RN. Skipping`);
    }
  } catch (e) {
    eventsLog(`Error while sending ${event} to RN - ${e}`);
  }

  try {
    if (window.parent) {
      window.parent.postMessage(
        JSON.stringify({
          type: "event",
          event,
          properties: properties || {},
        }),
        "*"
      );
      eventsLog(`${event} sent to parent window`);
    } else {
      eventsLog(`No parent window. Skipping`);
    }
  } catch (e) {
    eventsLog(`Error while sending ${event} to parent window - ${e}`);
  }
}

export function hostReload() {
  if (
    (window as any).AndroidHost &&
    typeof (window as any).AndroidHost.reload === "function"
  ) {
    (window as any).AndroidHost.reload();
    return true;
  }

  return false;
}

export function hostOnAppLoaded() {
  try {
    if (
      (window as any).AndroidHost &&
      typeof (window as any).AndroidHost.onAppReady === "function"
    ) {
      (window as any).AndroidHost.onAppReady();
    }
  } catch (e) {}

  // post a message to window
  try {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "action",
        action: "app_ready",
      })
    );
  } catch (e) {}
}

export function hostOnError() {
  try {
    if (
      (window as any).AndroidHost &&
      typeof (window as any).AndroidHost.onError === "function"
    ) {
      (window as any).AndroidHost.onError();
    }
  } catch (e) {}

  try {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type: "action",
        action: "app_error",
      })
    );
  } catch (e) {}
}

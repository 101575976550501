import { DenominationItem, VoucherCart } from "./voucher";
import { For, Show, createSignal } from "solid-js";
import { classNames } from "../../utils/etc";
import { toRupees } from "../../utils/number";

function PlusMinusButton(props: {
  onChange: (value: number) => void;
  value: number;
  minimumVouchers: number;
}) {
  return (
    <div class={"flex flex-row items-center gap-1"}>
      <i
        aria-label="minus"
        class="ph-fill ph-minus-circle text-[32px]"
        onClick={() =>
          props.onChange(Math.max(props.minimumVouchers, props.value - 1))
        }
      ></i>
      <Show
        when={props.value !== 0}
        fallback={
          <span class="box-border flex h-[40px] w-[50px] flex-shrink-0 items-center justify-center gap-1 rounded-lg border border-baseTertiaryDark text-center text-mediumBolder text-baseSecondaryMedium">
            {props.value}
          </span>
        }
      >
        <span class="title-secondary box-border flex h-[40px] w-[50px] flex-shrink-0 items-center justify-center gap-1 rounded-lg border border-baseTertiaryDark text-center text-mediumBolder text-basePrimaryDark">
          {props.value}
        </span>
      </Show>

      <i
        aria-label="plus"
        class="ph-fill ph-plus-circle text-[32px]"
        onClick={() => props.onChange(props.value + 1)}
      ></i>
    </div>
  );
}

export function SelectableVoucherDenominationItem(props: {
  denomination: DenominationItem;
  onCountChange: (count: number) => void;
  isSelected: boolean;
  onSelected: () => void;
}) {
  return (
    <div
      onClick={props.onSelected}
      class={classNames(
        "flex cursor-pointer flex-row items-center justify-between p-1",
        props.isSelected
          ? "rounded-xl border border-[#d8d8d8] bg-baseTertiaryMedium"
          : "border border-[#d8d8d800] opacity-50"
      )}
    >
      <div class="flex gap-1 ">
        <Show
          when={props.isSelected}
          fallback={<i class="ph ph-circle text-[20px]"></i>}
        >
          <i class="ph-fill ph-check-circle text-[20px]"></i>
        </Show>
        <span class="title-primary text-bolder text-basePrimaryMedium">
          {props.denomination.display}
        </span>
      </div>
      <PlusMinusButton
        minimumVouchers={1}
        onChange={props.onCountChange}
        value={props.denomination.quantity}
      />
    </div>
  );
}

export function VoucherDenominationItem(props: {
  denomination: DenominationItem;
  onCountChange: (count: number) => void;
}) {
  return (
    <div
      class={"flex cursor-pointer flex-row items-center justify-between p-1"}
    >
      <span class="tetx-bolder text-basePrimaryMedium">
        {props.denomination.display}
      </span>
      <PlusMinusButton
        minimumVouchers={0}
        onChange={props.onCountChange}
        value={props.denomination.quantity}
      />
    </div>
  );
}

export function SingleVoucherDenomination(props: {
  cart: VoucherCart;
  onChange: (amount: DenominationItem[]) => void;
  class?: string;
}) {
  const defaultDenomination = props.cart.denominations
    ? props.cart.denominations[0]
    : props.cart.voucherProduct!.amountConditions.defaultDenominationDetails[0];

  const denominationsData =
    props.cart.voucherProduct!.amountConditions.denominations!.map(
      (denomination) => {
        return {
          denomination: denomination,
          display: toRupees(denomination),
          quantity:
            denomination == defaultDenomination.denomination
              ? defaultDenomination.quantity
              : 1,
        } as DenominationItem;
      }
    );

  const selectedDenominationIndex = denominationsData.findIndex(
    (denomination) =>
      denomination.denomination == defaultDenomination.denomination
  );

  const [selectedDenomination, setSelectedDenomination] = createSignal<number>(
    selectedDenominationIndex
  );

  const [denominations, setDenominations] =
    createSignal<DenominationItem[]>(denominationsData);

  return (
    <div
      class={classNames(
        "box-border flex max-h-[314px] w-full flex-col gap-2 overflow-scroll pb-4",
        props.class
      )}
    >
      <For each={denominations()}>
        {(denomination, index) => (
          <SelectableVoucherDenominationItem
            onSelected={() => {
              setSelectedDenomination(index());
              props.onChange([denominations()[index()]]);
            }}
            isSelected={selectedDenomination() == index()}
            denomination={denomination}
            onCountChange={(count) => {
              const newDenominations = [...denominations()];
              setSelectedDenomination(index());
              newDenominations[index()] = {
                ...newDenominations[index()],
                quantity: count,
              };
              setDenominations(newDenominations);
              props.onChange([newDenominations[index()]]);
            }}
          />
        )}
      </For>
    </div>
  );
}

export function VoucherDenomination(props: {
  cart: VoucherCart;
  onChange: (amount: DenominationItem[]) => void;
  class?: string;
}) {
  if (
    props.cart.voucherProduct!.voucherProductProviderMetadata
      .onlySingleDenominationAllowed
  ) {
    return (
      <SingleVoucherDenomination
        cart={props.cart}
        onChange={props.onChange}
        class={props.class}
      />
    );
  }

  const defaultDenomination: { [key: number]: number } = {};

  for (const denomination of props.cart.voucherProduct!.amountConditions
    .defaultDenominationDetails) {
    defaultDenomination[denomination.denomination] = denomination.quantity;
  }

  if (props.cart.denominations) {
    for (const denomination of props.cart.denominations!) {
      defaultDenomination[denomination.denomination] = denomination.quantity;
    }
  }

  const den = props.cart.voucherProduct!.amountConditions.denominations!.map(
    (denomination) => {
      return {
        denomination: denomination,
        display: toRupees(denomination),
        quantity: defaultDenomination[denomination] || 0,
      } as DenominationItem;
    }
  );

  const [denominations, setDenominations] =
    createSignal<DenominationItem[]>(den);

  return (
    <div
      class={classNames(
        "box-border flex max-h-[314px] w-full flex-col gap-2 overflow-scroll pb-4",
        props.class
      )}
    >
      <For each={denominations()}>
        {(denomination, index) => (
          <VoucherDenominationItem
            denomination={denomination}
            onCountChange={(count) => {
              const newDenominations = [...denominations()];
              newDenominations[index()] = {
                ...newDenominations[index()],
                quantity: count,
              };
              setDenominations(newDenominations);
              props.onChange(
                denominations().filter(
                  (denomination) => denomination.quantity > 0
                )
              );
            }}
          />
        )}
      </For>
    </div>
  );
}

import { FetchOptions } from "~/types";

export type RequestData = {
  url: string;
  method: "GET" | "POST" | "PATCH" | "PUT";
  headers?: Record<string, string>;
  body?: any;
  responseTypeNonJson?: boolean;
  options?: FetchOptions;
};

export abstract class RepoBase {
  abstract fetch<T>(request: RequestData): Promise<T>;

  async get<T>(request: {
    url: string;
    headers?: Record<string, string>;
    responseTypeNonJson?: boolean;
    options?: FetchOptions;
  }): Promise<T> {
    return this.fetch<T>({
      ...request,
      method: "GET",
    });
  }

  async post<T>(request: {
    url: string;
    body: any;
    headers?: Record<string, string>;
    responseTypeNonJson?: boolean;
    options?: FetchOptions;
  }): Promise<T> {
    return this.fetch<T>({
      ...request,
      method: "POST",
    });
  }

  async patch<T>(request: {
    url: string;
    body: any;
    headers?: Record<string, string>;
    responseTypeNonJson?: boolean;
    options?: FetchOptions;
  }): Promise<T> {
    return this.fetch<T>({
      responseTypeNonJson: true,
      ...request,
      method: "PATCH",
    });
  }

  async put<T>(request: {
    url: string;
    body: any;
    headers?: Record<string, string>;
    responseTypeNonJson?: boolean;
    options?: FetchOptions;
  }): Promise<T> {
    return this.fetch<T>({
      responseTypeNonJson: true,
      ...request,
      method: "PUT",
    });
  }
}

import { For } from "solid-js";
import { makeUPIAppRequest } from "../../data/products";
import { rewriteURL, upiApps } from "../../data/upi_apps";
import { classNames } from "../../utils/etc";
import { ButtonRegular } from "../../widgets/button";
import { PhosphorIcon } from "~/widgets/icons";

export function UpiApps(props: {
  orderId: string;
  amount: number;
  onAfterClick?: () => void;
}) {
  const assets = Object.values(upiApps).map((app) => {
    return {
      asset: app.icon,
      name: app.name,
      backendName: app.backendName,
      app: app,
      imageClass: app.noBackground ? "p-2" : "",
      class: classNames(
        "flex flex-col items-center mr-2.5 mt-2.5 gap-2 overflow-visible"
      ),
    };
  });

  return (
    <div class="bottom-0 left-0 right-0 flex flex-col items-stretch p-3">
      <div class="gap-[10px 20px] relative grid grid-cols-[repeat(4,_1fr)] pt-2">
        <For each={assets}>
          {(asset) => (
            <div
              class={asset.class}
              onClick={async () => {
                if (props.onAfterClick) {
                  props.onAfterClick();
                }

                const order = await makeUPIAppRequest(
                  props.orderId,
                  asset.backendName
                );

                const url = rewriteURL(order.link, asset.app);

                let loc = window.top?.location;
                if (loc) {
                  loc.href = url;
                } else {
                  window.location.href = url;
                }
              }}
            >
              <img
                src={asset.asset}
                class={classNames(
                  "box-border h-[60px] w-[60px] cursor-pointer rounded-lg border border-baseSecondaryDark object-contain",
                  asset.imageClass
                )}
              />
              <span class="text-center text-[10px] font-semibold leading-[140%] text-textDark">
                {asset.name}
              </span>
            </div>
          )}
        </For>
      </div>
    </div>
  );
}

export function PayWithUpiButton(props: {
  orderId: string;
  onAfterClick?: () => void;
}) {
  return (
    <div>
      <ButtonRegular
        isEnabled={true}
        onClick={async () => {
          if (props.onAfterClick) {
            props.onAfterClick();
          }

          const order = await makeUPIAppRequest(props.orderId, "PAYTM");
          let loc = window.top?.location;
          if (loc) {
            loc.href = order.link;
          } else {
            window.location.href = order.link;
          }
        }}
        class={"button-filled box-border min-w-[300px] self-center"}
      >
        <span>Select UPI app to pay</span>
        <PhosphorIcon name="caret-right" fontSize={16} />
      </ButtonRegular>
    </div>
  );
}

import {
  createEffect,
  createResource,
  createSignal,
  Match,
  onCleanup,
  Show,
  Switch,
} from "solid-js";
import { upiLogoLongListMobile } from "~/assets/assets";
import { getPaymentOrder } from "~/data/products";
import { CloseButton } from "~/shared_states/modal";
import { isAndroid, isIOS } from "~/utils/platform";
import { PayWithUpiButton, UpiApps } from "./upi_apps";
import { ButtonRegular, ThreeDotLoader } from "~/widgets/button";
import { PaymentQrCode } from "./qr_code";
import {
  PaymentOrderDetails,
  PaymentOrderStatus,
  SquidOrderDetailResponse,
  SquidOrderStatus,
} from "~/server/types/order";
import { useNavigate } from "@solidjs/router";
import { Divider } from "~/widgets/divider";
import { Spacer } from "~/widgets/spacer";
import { clientRepo } from "~/server/apis/client_repo";

function PaymentStatus(props: {
  status: PaymentOrderStatus | undefined;
  showLoader: boolean;
}) {
  return (
    <Switch>
      <Match when={props.status == "EXPIRED"}>
        <span class="max-w-[200px] text-center text-medium text-errorDark">
          Payment expired, <span> please close and retry</span>
        </span>
        <span> please close and retry</span>
      </Match>

      <Match when={props.status == "FAILED"}>
        <span class="max-w-[200px] text-center text-medium text-errorDark">
          Payment failed, <span> please close and retry</span>
        </span>
      </Match>
      <Match when={props.status == "ACTIVE" && props.showLoader}>
        <span class="">Processing payment</span>
        <Spacer height={8} />
        <ThreeDotLoader color="#000" />
      </Match>
    </Switch>
  );
}

export function PaymentLoader(props: {
  orderId: string;
  amount: number;
  brandImageUrl: string;
  onClose: () => void;
  reInit: () => void;
  onPaymentComplete: () => void;
  onPaymentFailed: (failureReason: string) => void;
  mode?: string;
  procurementOrderId?: string;
}) {
  const navigate = useNavigate();

  const [orderStatus, setOrderStatus] =
    createSignal<PaymentOrderStatus>("ACTIVE");
  const [procurementOrderStatus, setProcurementOrderStatus] =
    createSignal<SquidOrderStatus>("INITIATED");

  const [order, manageOrder] = createResource<any>(async () => {
    console.log("order fetch!!");

    let order: PaymentOrderDetails | SquidOrderDetailResponse;

    if (props.mode == "squid" && props.procurementOrderId) {
      order = await clientRepo.getSquidOrderDetail({
        orderId: props.procurementOrderId,
      });

      if (procurementOrderStatus() != order.status) {
        setProcurementOrderStatus(order.status);
      }
    } else {
      order = await getPaymentOrder(props.orderId);
      console.log("getPaymentOrder@@");
      if (orderStatus() != order.status) {
        setOrderStatus(order.status);
      }
    }
    return order;
  }, {});

  const [showLoader, setShowLoader] = createSignal(false);

  const interval = setInterval(() => {
    console.log("interval trigger");

    if (!order.loading) {
      manageOrder.refetch();
    }
  }, 5000);

  onCleanup(() => {
    clearInterval(interval);
  });

  createEffect(() => {
    if (!order()) return;
    if (order.loading) return;
    if (order.error) return;

    if (order()?.status != "ACTIVE") {
      clearInterval(interval);
      if (order()!.status == "PAID") {
        props.onPaymentComplete();
      }

      if (order()!.status == "FAILED") {
        props.onPaymentFailed(order()!.failureReason ?? "Payment failed");
      }
    }
  });

  createEffect(() => {
    if (!procurementOrderStatus()) return;
    if (order.loading) return;
    if (order.error) return;

    if (procurementOrderStatus() != "INITIATED") {
      clearInterval(interval);
      if (
        procurementOrderStatus() == "FULFILLED" ||
        procurementOrderStatus() == "PARTIALLY_FULFILLED"
      ) {
        props.onPaymentComplete();
      }

      if (procurementOrderStatus() == "FAILED") {
        props.onPaymentFailed(order()!.failureReason ?? "Payment failed");
      }
    }
  });

  return (
    <div>
      <Switch>
        <Match when={isIOS()}>
          <div class="top-2 flex justify-center pb-4 pl-4 pr-4">
            <div class="bg-whiteitems-center relative flex max-w-[475px] flex-col gap-4 overflow-hidden rounded-3xl pb-0">
              <div class="m-2 rounded-2xl border border-gray-100 bg-gradient-to-b from-[#dfedf1] via-baseTertiaryLight to-baseTertiaryLight">
                <CloseButton
                  class="absolute right-5 top-5"
                  onClick={props.onClose}
                />
                <div class="mt-6 flex flex-col content-center items-center justify-start">
                  <div class="text-h4 text-textDark">₹{props.amount}</div>
                  <div class="text-bold text-textDark">Pay via UPI Apps</div>
                </div>
                <UpiApps
                  amount={props.amount}
                  onAfterClick={() => setShowLoader(true)}
                  orderId={props.orderId}
                />
                <div class="mx-4 my-2 flex flex-col content-center items-center">
                  <PaymentStatus
                    status={orderStatus()}
                    showLoader={showLoader()}
                  />
                </div>
                <Divider bgColor="#EDEEF1" />
                <div class="inline-flex flex-col items-center justify-start self-stretch">
                  <div class="shrink grow basis-0 px-2 py-4 text-center text-medium leading-[18.20px] text-baseSecondaryMedium">
                    Note: You’ll be redirected to another page once payment is
                    processed
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Match>

        <Match when={isAndroid()}>
          <div class="top-2 flex justify-center pb-4 pl-4 pr-4">
            <div class="relative flex max-w-[475px] flex-col items-center gap-4 overflow-hidden rounded-3xl bg-white pb-0">
              <div class="m-2 rounded-2xl border border-gray-100 bg-gradient-to-b from-[#dfedf1] via-baseTertiaryLight to-baseTertiaryLight">
                {/*<CloseButton class="absolute right-5 top-5" onClick={props.onClose} />*/}

                <div class="mt-6 flex flex-col content-center items-center justify-start">
                  <div class="text-h4 text-textDark">₹{props.amount}</div>
                  <div class="text-bold text-textDark">Pay via UPI Apps</div>

                  <div class="mx-4 my-5 flex flex-col content-center items-center rounded-xl border bg-baseTertiaryMedium py-4">
                    <img
                      src={upiLogoLongListMobile}
                      class="h-[64px] w-[294px]"
                      alt="Upi apps - Phonepe, Gpay, Paytm"
                    />
                    <div class="mt-3 text-medium text-baseSecondaryMedium">
                      ... or any UPI app
                    </div>
                  </div>
                  <div class="mx-4 flex flex-col content-center items-center gap-2">
                    <PaymentStatus
                      status={orderStatus()}
                      showLoader={showLoader()}
                    />

                    <Show when={orderStatus() == "ACTIVE" && !showLoader()}>
                      <PayWithUpiButton
                        onAfterClick={() => setShowLoader(true)}
                        orderId={props.orderId}
                      />
                    </Show>
                    <Show when={orderStatus() == "ACTIVE" && showLoader()}>
                      <ButtonRegular
                        class="box-border min-w-[300px] self-center"
                        isEnabled={true}
                        onClick={props.onClose}
                      >
                        <span>Cancel payment</span>
                      </ButtonRegular>
                    </Show>
                  </div>
                </div>
                <div class="mt-4">
                  <Divider bgColor="#EDEEF1" />
                </div>
                <div class="inline-flex flex-col items-center justify-start self-stretch">
                  <div class="shrink grow basis-0 px-2 py-4 text-center text-medium leading-[18.20px] text-baseSecondaryMedium">
                    Note: You’ll be redirected to another page once payment is
                    processed
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Match>

        <Match when={true}>
          <PaymentQrCode
            orderId={props.orderId}
            amount={props.amount}
            onClose={props.onClose}
            reInit={props.reInit}
            brandImageUrl={props.brandImageUrl}
          />
        </Match>
      </Switch>
    </div>
  );
}

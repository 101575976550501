import { goBack } from "~/shared_states/modal";
import { classNames } from "../utils/etc";
import styles from "./button.module.scss";
import { PhosphorIcon } from "./icons";

export function ButtonRegular(props: {
  children: any;
  onClick: any;
  class?: string;
  isEnabled?: boolean;
  isLoading?: boolean;
  height?: string;
  isOutlined?: boolean;
  isInitiallyEnabled?: boolean;
  isRectangular?: boolean;
}) {
  return (
    <button
      disabled={
        !(props.isInitiallyEnabled || props.isEnabled) || props.isLoading
      }
      aria-busy={props.isLoading ? "true" : "false"}
      style={{
        height: props.height,
      }}
      class={classNames(
        props.class,
        "box-border flex h-[52px] cursor-pointer items-center justify-center gap-1 bg-basePrimaryDark p-4 text-center text-bold  outline-none [transition:200ms_all_ease-in-out]",
        props.isInitiallyEnabled || props.isEnabled || props.isLoading
          ? "bg-basePrimaryDark"
          : "bg-[#b8b8b8] text-baseTertiaryLight",
        props.isOutlined
          ? "border border-baseTertiaryDark bg-transparent text-basePrimaryDark"
          : "text-baseTertiaryLight",
        props.isRectangular ? "rounded-2xl" : "rounded-[78px]"
      )}
      onClick={() => {
        if (props.isEnabled && !props.isLoading) {
          props.onClick();
        }
      }}
    >
      {props.isLoading ? <ThreeDotLoader /> : props.children}
    </button>
  );
}

export function ThreeDotLoader(props: { color?: string; class?: string }) {
  return (
    <div class={styles.threeDotLoader}>
      <div
        class={`${styles.dot1} ${props.class}`}
        style={{ "background-color": props.color }}
      ></div>
      <div
        class={`${styles.dot2} ${props.class}`}
        style={{ "background-color": props.color }}
      ></div>
      <div
        class={`${styles.dot3} ${props.class}`}
        style={{ "background-color": props.color }}
      ></div>
    </div>
  );
}

export function BackButton() {
  return (
    <PhosphorIcon
      name="arrow-left"
      class={styles.backButton}
      onClick={() => goBack()}
      label="Back"
    />
  );
}

type SwitchButtonProps = {
  class?: string;
  isSmall?: boolean;
  value: boolean;
  onChange: (value: boolean) => void;
};

export function SwitchButton(props: SwitchButtonProps) {
  return (
    <div
      class={classNames(
        styles.switchButton,
        props.value ? styles.on : styles.off,
        props.isSmall ? styles.small : null,
        props.class
      )}
      onClick={(event) => {
        event.stopPropagation();
        props.onChange(!props.value);
      }}
    >
      <div class={styles.switchCircle}></div>
    </div>
  );
}

import { createSignal } from "solid-js";
import { getVoucherType } from "../screens/voucher_edit/voucher";
import { hubbleFetch } from "../utils/fetch";
import { defaultHeaders } from "./auth";
import { CouponsResponse } from "./coupons";
import {
  OrderDetails,
  PaymentOrderDetails,
  RedemptionParams,
  RedemptionResponse,
  ProviderCustomData,
} from "../server/types/order";
import { RewardTypeType, TransactionDetailV4Response } from "./transaction";
import { config } from "./config";
import {
  HowToRedeemDetailsResponse,
  SimulatedTransactionResponse,
  VoucherProduct,
} from "~/server/types/brand";
import { Cookie, Header } from "~/types";
import { getCookie } from "~/utils/client_cookie";
import { getOS } from "~/utils/platform";
import { newRequestId } from "~/utils/common";

export interface SearchResult {
  nextCursor?: any;
  data: Section[];
}
export interface Section {
  sectionName?: "MAIN_PRODUCTS" | "OTHER_RELATED_PRODUCTS";
  products: HubbleBrandProduct[];
  tag?: any;
}
export interface HubbleBrandProduct {
  id: string;
  type: string;
  voucherProduct: SearchVoucherProduct;
  brandKey: string;
  displayTags: string[];
  category?: string;
  tags: string[];
  applicableCouponDetails?: any;
}
export interface SearchVoucherProduct {
  title: string;
  backgroundImageUrl: string;
  iconImageUrl: string;
  discountPercentage: number;
  rewardType: RewardTypeType;
  sqLogoUrl?: string;
  isLandingPageAvailable: boolean;
  subscriptionDetails?: any;
  status: "ACTIVE" | "INACTIVE";
}

export function getAllProducts(): Promise<SearchResult> {
  return hubbleFetch(`${config.baseUrl}/v3/store/products/search`, {
    headers: getCompleteHeaders(),
  });
}

export function getTopNProducts(n: number): Promise<SearchResult> {
  return hubbleFetch(
    `${config.baseUrl}/v3/store/products/search?pageNo=0&limit=${n}`,
    {
      headers: getCompleteHeaders(),
    }
  );
}

export function getVoucherProduct(productId: string): Promise<VoucherProduct> {
  const url = new URL(
    `${config.baseUrl}/v1/store/products/voucher-product/${productId}`
  );

  return hubbleFetch(url, {
    headers: getCompleteHeaders(),
  }).then((data) => {
    data["voucherProductType"] = getVoucherType(data);
    return data;
  });
}

export function getHowToRedeemDetails(
  productId: string
): Promise<HowToRedeemDetailsResponse> {
  const url = new URL(
    `${config.baseUrl}/v1/store/products/voucher-product/${productId}/how-to-redeem-details`
  );

  return hubbleFetch(url, {
    headers: getCompleteHeaders(),
  });
}

export function searchProductsByQuery(query: string): Promise<SearchResult> {
  const url = new URL(`${config.baseUrl}/v3/store/products/search`);

  url.searchParams.append("q", query);
  return hubbleFetch(url, {
    headers: getCompleteHeaders(),
  });
}

export function searchByCategory(
  category: string,
  pageNumber?: number,
  limit?: number
): Promise<SearchResult> {
  const url = new URL(`${config.baseUrl}/v3/store/products/search`);

  if (pageNumber !== undefined && limit !== undefined) {
    url.searchParams.append("limit", `${limit}`);
    url.searchParams.append("pageNo", `${pageNumber}`);
  }
  if (category) {
    url.searchParams.append("category", category);
  }

  return hubbleFetch(url, {
    headers: getCompleteHeaders(),
  });
}

export function getPopularBrands(): Promise<SearchResult> {
  const url = new URL(`${config.baseUrl}/v3/store/products/search`);
  url.searchParams.append("category", "POPULAR_BRANDS");
  return hubbleFetch(url, {
    headers: getCompleteHeaders(),
  });
}

export interface DryRunTransaction {
  variantId?: string;
  voucherProductId: string;
  amount?: number;
  denominationDetails?: { quantity: number; denomination: number }[];
  consumeCoins?: boolean;
  autoApplyReferralCode?: boolean;
  couponCode?: string;
}

export function dryRunTransaction(
  params: DryRunTransaction
): Promise<SimulatedTransactionResponse> {
  const url = new URL(`${config.baseUrl}/v3/redemptions/simulate`);
  return hubbleFetch(url, {
    method: "POST",
    body: JSON.stringify(params),
    headers: getCompleteHeaders(),
  });
}

export function getCompleteHeaders(): HeadersInit {
  let completeHeaders = defaultHeaders();
  let sidFromCookie = getCookie(Cookie.SessionId);
  if (!completeHeaders[Header.SessionId]) {
    completeHeaders = {
      ...completeHeaders,
      [Header.SessionId]:
        sidFromCookie || localStorage.getItem("sessionId") || "",
    };
  }
  completeHeaders = {
    ...completeHeaders,
    [Header.HostOs]: getOS() ?? "UNKNOWN",
    [Header.RequestId]: newRequestId(),
  };

  return completeHeaders;
}

export async function initPgRedemption(
  params: RedemptionParams
): Promise<RedemptionResponse> {
  const path = "v2/redemptions/provider/pg/initiate";

  return hubbleFetch(`${config.baseUrl}/${path}`, {
    headers: getCompleteHeaders(),
    method: "POST",
    body: JSON.stringify(params),
  });
}

export async function getPaymentOrder(
  orderId: string
): Promise<PaymentOrderDetails> {
  const path = `v1/orders/${orderId}`;
  return hubbleFetch(`${config.baseUrl}/${path}`, {
    headers: getCompleteHeaders(),
    method: "GET",
  });
}

// final path = 'v2/orders/$orderId/pay';
// return http.post(
//   uri: Uri.parse('$baseUrl/$path').toString(),
//   body: {'mode': 'STANDARD_CHECKOUT', 'customData': {}},
//   success: (json) => OrderPayResponse.fromJson(json),
// );

export interface PayOrderResponse {
  action: string;
  provider: string;
  providerOrderId: string;
  providerPaymentId: any;
  link: any;
  statusUrl: string;
  returnUrl: string;
  providerCustomData: ProviderCustomData;
}

export async function payOrderStandardCheckout(
  orderId: string
): Promise<PayOrderResponse> {
  const path = `v2/orders/${orderId}/pay`;
  return hubbleFetch(`${config.baseUrl}/${path}`, {
    headers: getCompleteHeaders(),
    method: "POST",
    body: JSON.stringify({ mode: "STANDARD_CHECKOUT", customData: {} }),
  });
}

export async function getStoreOrderDetails(
  orderId: string,
  headers?: HeadersInit
): Promise<OrderDetails> {
  const path = `v1/store/orders/${orderId}`;
  let order = await hubbleFetch(`${config.baseUrl}/${path}`, {
    headers: {
      ...getCompleteHeaders(),
      ...headers,
    },
    method: "GET",
  });
  return order;
}

export async function getCoupons(params: {
  amount: number;
  voucherProductId: string;
}): Promise<CouponsResponse> {
  const path = "v1/coupons";
  const url = new URL(`${config.baseUrl}/${path}`);
  url.searchParams.append("defaultAmount", params.amount.toString());
  url.searchParams.append("triggerAction", "REDEMPTION");
  url.searchParams.append("voucherProductId", params.voucherProductId);
  url.searchParams.append("planType", "BASIC");
  return hubbleFetch(url.toString(), {
    headers: getCompleteHeaders(),
    method: "GET",
  });
}

interface RedemptionTransactionsResponse {
  data: RedemptionTransaction[];
}

export interface RedemptionTransaction {
  id: string;
  description: string;
  logoImageUrl: string;
  amount: number;
  dateTime: string;
  orderStatus:
    | "UNKNOWN"
    | "INITIALISED"
    | "PROCESSING"
    | "COMPLETED"
    | "FAILED"
    | "REVERSED"
    | "CANCELLED";
  refundStatus: "UNKNOWN" | "SUCCESS" | "FAILED" | "INITIATED" | "PENDING";
}

export async function getRedemptionTransactions(): Promise<RedemptionTransactionsResponse> {
  const path = "v3/redemptions/transactions";
  return hubbleFetch(`${config.baseUrl}/${path}`, {
    headers: getCompleteHeaders(),
    method: "GET",
  });
}

export async function getRedemptionTransactionDetail(
  id: string
): Promise<TransactionDetailV4Response> {
  const path = `v3/redemptions/transactions/${id}`;
  let transactionDetail = await hubbleFetch(`${config.baseUrl}/${path}`, {
    headers: getCompleteHeaders(),
    method: "GET",
  });

  return transactionDetail;
}

export interface Category {
  id: string;
  name: string;
  metadata: CategoryMetadata;
}

export interface CategoryMetadata {
  promoImageUrl: string;
  title: string;
  description: string;
  thumbnailUrl: string;
  activeBrandsCount: number;
}

export async function getCategories(): Promise<{ data: Category[] }> {
  const path = `v1/store/categories`;
  return hubbleFetch(`${config.baseUrl}/${path}`, {
    headers: getCompleteHeaders(),
    method: "GET",
  });
}

// @override
// Future<Result<OrderPayResponse>> makeUPIAppRequest({
//   required String orderId,
//   required String upiApp,
// }) {
//   final path = 'v2/orders/$orderId/pay';
//   return http.post(
//     uri: Uri.parse('$baseUrl/$path').toString(),
//     body: {
//       'mode': 'UPI_APP',
//       'customData': {
//         'upiApp': upiApp,
//       }
//     },
//     success: (json) => OrderPayResponse.fromJson(json),
//   );
// }

export async function makeUPIAppRequest(
  orderId: string,
  upiApp: string
): Promise<PayOrderResponse> {
  const path = `v2/orders/${orderId}/pay`;
  return hubbleFetch(`${config.baseUrl}/${path}`, {
    headers: {
      ...getCompleteHeaders(),
    },
    method: "POST",
    body: JSON.stringify({ mode: "UPI_APP", customData: { upiApp } }),
  });
}

export async function giveRazorpaySuccessCallbackToServer() {}

export async function makeUPICollectRequest(
  orderId: string,
  upiId: string
): Promise<void> {
  const path = `v2/orders/${orderId}/pay`;
  return hubbleFetch(`${config.baseUrl}/${path}`, {
    headers: {
      ...getCompleteHeaders(),
    },
    method: "POST",
    body: JSON.stringify({ mode: "UPI_COLLECT", customData: { upiId } }),
  });
}

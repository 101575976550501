import { createEffect, createSignal } from "solid-js";
import { classNames } from "../../utils/etc";

export function NumberKeyboard(props: {
  value: number;
  class?: string;
  onChange: (value: number) => void;
}) {
  const [value, setValue] = createSignal<string>("");
  const [activeButton, setActiveButton] = createSignal<number | null>(null);

  const fireEvent = () => {
    const number = parseFloat(value());
    props.onChange(Number.isNaN(number) ? 0 : number);
  };

  createEffect(() => {
    setValue(props.value.toString());
  });

  function handleNumberClick(number: string) {
    const newNumber = value() + number;
    // if text is a valid number, update the card worth
    const floatRegex = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;

    if (floatRegex.test(newNumber)) {
      setValue(newNumber);
      fireEvent();
    }
  }

  function handleClearClick() {
    setValue("");
    fireEvent();
  }

  function handleBackspaceClick() {
    setValue(value().slice(0, -1));
    fireEvent();
  }

  function numberHandlerFactory(number: number) {
    const numString = number.toString();
    return () => {
      handleNumberClick(numString);
      setActiveButton(number);
      setTimeout(() => setActiveButton(null), 1000);
    };
  }

  const onDblClick = (event: MouseEvent) => {
    event.preventDefault();
  };

  const getButtonClass = (number: number) => {
    return classNames(
      activeButton() === number
        ? "bg-baseTertiaryMedium"
        : "bg-baseTertiaryLight",
      "title-section box-border flex h-[50px] cursor-pointer flex-col items-center justify-center gap-2.5 self-stretch rounded-xl border border-baseTertiaryDark p-2.5 text-center text-h4 leading-[24px]"
    );
  };

  return (
    <div
      class={classNames(
        "my-2.5 grid w-full grid-cols-[repeat(3,_1fr)] gap-2",
        props.class
      )}
    >
      <div
        class={getButtonClass(1)}
        onDblClick={onDblClick}
        onClick={numberHandlerFactory(1)}
      >
        1
      </div>
      <div
        class={getButtonClass(2)}
        onDblClick={onDblClick}
        onClick={numberHandlerFactory(2)}
      >
        2
      </div>
      <div
        class={getButtonClass(3)}
        onDblClick={onDblClick}
        onClick={numberHandlerFactory(3)}
      >
        3
      </div>
      <div
        class={getButtonClass(4)}
        onDblClick={onDblClick}
        onClick={numberHandlerFactory(4)}
      >
        4
      </div>
      <div
        class={getButtonClass(5)}
        onDblClick={onDblClick}
        onClick={numberHandlerFactory(5)}
      >
        5
      </div>
      <div
        class={getButtonClass(6)}
        onDblClick={onDblClick}
        onClick={numberHandlerFactory(6)}
      >
        6
      </div>
      <div
        class={getButtonClass(7)}
        onDblClick={onDblClick}
        onClick={numberHandlerFactory(7)}
      >
        7
      </div>
      <div
        class={getButtonClass(8)}
        onDblClick={onDblClick}
        onClick={numberHandlerFactory(8)}
      >
        8
      </div>
      <div
        class={getButtonClass(9)}
        onDblClick={onDblClick}
        onClick={numberHandlerFactory(9)}
      >
        9
      </div>
      <div
        class="box-border flex h-[50px] cursor-pointer flex-col items-center justify-center gap-2.5 self-stretch rounded-xl border border-baseTertiaryDark bg-baseTertiaryLight p-2.5 text-center text-buttonMedium leading-[24px] text-basePrimaryDark hover:bg-baseTertiaryMedium active:bg-baseTertiaryMedium"
        onDblClick={onDblClick}
        onClick={handleClearClick}
      >
        Clear
      </div>
      <div
        class={getButtonClass(0)}
        onDblClick={onDblClick}
        onClick={numberHandlerFactory(0)}
      >
        0
      </div>
      <div
        class="title-section box-border flex h-[50px] cursor-pointer flex-col items-center justify-center gap-2.5 self-stretch rounded-xl border border-baseTertiaryDark bg-baseTertiaryLight p-2.5 text-center text-[24px] font-semibold leading-[24px] text-basePrimaryDark hover:bg-baseTertiaryMedium active:bg-baseTertiaryMedium"
        onDblClick={onDblClick}
        onClick={handleBackspaceClick}
      >
        <i class="ph-bold ph-backspace"></i>
      </div>
    </div>
  );
}

import { Accessor, createSignal, For, Match, Show, Switch } from "solid-js";
import { createDebounce } from "../../utils/debounce";
import {
  Category,
  getCategories,
  HubbleBrandProduct,
  searchByCategory,
  searchProductsByQuery,
  SearchResult,
} from "../../data/products";
import { BrandListing } from "./home_helper";
import { ResourceLoader } from "../../widgets/resource_loader";
import { classNames } from "../../utils/etc";
import { goBack } from "../../shared_states/modal";
import { sdkEventManager, HubbleEvent } from "../../data/events";
import { DottedLoader } from "../../widgets/loader";
import { routerLog } from "~/utils/logging";
import { HubbleBranding } from "~/widgets/branding";
import { useClassicContext } from "~/data/classic_context";
import { PaginationRequest } from "~/server/types/search";
import { brandsCount } from "~/data/static";
import { noResultsFound } from "~/assets/assets";

export const [isSearchVisible, setSearchVisible] = createSignal(false);
export const [selectedSearchCategory, setSelectedSearchCategory] =
  createSignal("");
const [searchProducts, setSearchProducts] = createSignal<HubbleBrandProduct[]>(
  []
);
const [relatedProducts, setRelatedProducts] = createSignal<
  HubbleBrandProduct[]
>([]);

const [isRunning, callDebounce] = createDebounce(1000);
const [paginationDetails, setPaginationDetails] =
  createSignal<PaginationRequest>({
    pageNo: 0,
    limit: 20,
  });

const [hasMore, setHasMore] = createSignal(true);

function resetPagination() {
  setPaginationDetails({
    pageNo: 0,
    limit: 20,
  });
}

const scrollNext = async () => {
  const newPageNumber = paginationDetails().pageNo + 1;
  setPaginationDetails((previousDetails) => ({
    ...previousDetails,
    pageNo: newPageNumber,
  }));

  const result = await searchByCategory(
    selectedSearchCategory(),
    newPageNumber,
    paginationDetails().limit
  );

  if (result.nextCursor === null) {
    setHasMore(false);
  }

  for (const section of result.data) {
    if (section.sectionName === "MAIN_PRODUCTS") {
      setSearchProducts((previousProducts) => [
        ...previousProducts,
        ...section.products,
      ]);
    }
  }
};

const [searchText, setSearchText] = createSignal("");

export function selectASearchCategory(category: string) {
  if (!isSearchVisible()) {
    routerLog(
      `Category clicked. Opening search. Setting search visible to true.`
    );
    routerLog(`Doing empty push to browser history`);
    history.pushState({}, "", "");
  }

  setSelectedSearchCategory(category);
  setSearchText("");
  setSearchVisible(true);

  resetPagination();
  setHasMore(true);

  _searchHandler();

  sdkEventManager.sendEvent(HubbleEvent.VISIT_CATEGORY, {
    categoryName: category,
  });
}

export function openSearchPage() {
  if (isSearchVisible()) {
    return;
  }

  sdkEventManager.sendEvent(HubbleEvent.VISIT_SEARCH);

  routerLog(`Opening search. Setting search visible to true.`);
  routerLog(`Doing empty push to browser history`);
  history.pushState({}, "", "");
  setSelectedSearchCategory("");
  resetPagination();
  setHasMore(true);
  setSearchText("");
  setSearchVisible(true);
  _searchHandler();
}

export function closeSearchPage() {
  setSelectedSearchCategory("");
  setSearchText("");
  resetPagination();
  setHasMore(true);
  setSearchVisible(false);
  routerLog(`Closed search. Setting search visible to false.`);
}

function _searchHandler() {
  setSearchProducts([]);
  setRelatedProducts([]);

  callDebounce({
    callback: async () => {
      var result: SearchResult | undefined;

      if (searchText() == "") {
        if (selectedSearchCategory() === "") {
          result = await searchByCategory(
            selectedSearchCategory(),
            paginationDetails().pageNo,
            paginationDetails().limit
          );
        } else {
          result = await searchByCategory(selectedSearchCategory());
        }
      } else {
        sdkEventManager.sendEvent(HubbleEvent.SEARCH_QUERY, {
          query: searchText(),
        });
        result = await searchProductsByQuery(searchText());
      }

      for (const section of result.data) {
        if (section.sectionName === "MAIN_PRODUCTS") {
          setSearchProducts(section.products);
        } else if (section.sectionName === "OTHER_RELATED_PRODUCTS") {
          setRelatedProducts(section.products);
        }
      }
    },
  });
}

export function SearchOverlay({ close }: { close: () => void }) {
  const { data } = useClassicContext();

  const showCategoriesFromContext =
    data.isLoggedIn &&
    data.mode === "sdk" &&
    data.localClientConfig.showCategories;

  function headerText() {
    return searchText() != ""
      ? 'Search results for "' + searchText() + '"'
      : selectedSearchCategory() !== ""
        ? searchProducts().length +
          (searchProducts().length > 1 ? " brands" : " brand")
        : brandsCount + "+ brands";
  }

  return (
    <div
      class={
        isSearchVisible()
          ? "fixed bottom-0 left-0 right-0 top-0 z-[1000] flex flex-col"
          : ""
      }
      style={{
        background:
          "var(--Background-Gradient, linear-gradient(98deg, rgba(0, 175, 84, 0.07) 3.51%, rgba(255, 233, 114, 0.15) 92.74%), #edeef1)",
      }}
    >
      <div class="bg-baseTertiaryLight px-5 pb-4 pt-5">
        <div
          class="primary-radius flex h-12 flex-shrink-0 items-center gap-3 rounded-3xl border border-baseTertiaryDark bg-baseTertiaryLight px-4 shadow-searchBar"
          onClick={openSearchPage}
        >
          <Show
            when={isSearchVisible()}
            fallback={
              <i
                class={classNames(
                  "ph ph-magnifying-glass",
                  "h-5 w-5 text-textNormal"
                )}
              ></i>
            }
          >
            <i
              class={"ph ph-arrow-left cursor-pointer"}
              onClick={() => {
                goBack();
              }}
            ></i>
          </Show>
          <input
            id="classic-search-input"
            placeholder="Search for your favorite brands"
            class="body-small w-full border-none bg-none text-f16 text-basePrimaryDark focus:outline-none active:outline-none"
            value={searchText()}
            onInput={(event) => {
              setSearchText(event.target.value);
              _searchHandler();
            }}
          />
          <Show when={isSearchVisible() && searchText() != ""}>
            <i
              onclick={() => {
                setSearchText("");
                const input = document.getElementById("classic-search-input");
                input?.focus();
              }}
              class={classNames("ph ph-x", "h-5 w-5 text-textNormal")}
            ></i>
          </Show>
          <Show when={isSearchVisible() && searchText() == ""}>
            <i
              onclick={() => {
                const input = document.getElementById("classic-search-input");
                input?.focus();
              }}
              class={classNames(
                "ph ph-magnifying-glass",
                "h-5 w-5 text-textNormal"
              )}
            ></i>
          </Show>
        </div>
      </div>

      <Show when={isSearchVisible()}>
        <Show when={searchText() == "" && showCategoriesFromContext}>
          <CategoryScroll
            onCategoriesLoaded={(_) => {}}
            onSelectCategory={selectASearchCategory}
            selectedCategory={selectedSearchCategory()}
          />
        </Show>
        <Show when={searchProducts().length == 0 || isRunning()}>
          <NoResultsFound isRunning={isRunning} text={searchText} />
        </Show>
        <div class="w-full overflow-y-scroll pt-2.5">
          <Show when={searchProducts().length > 0}>
            <Show when={selectedSearchCategory().length <= 0}>
              <BrandListing
                class="flex flex-col gap-2.5 rounded-2xl bg-baseTertiaryLight py-5"
                header={showCategoriesFromContext ? headerText() : ""}
                products={searchProducts()}
                scrollNext={scrollNext}
                hasMore={hasMore}
              />
            </Show>
            <Show when={selectedSearchCategory().length > 0}>
              <BrandListing
                class="flex flex-col gap-2.5 rounded-2xl bg-baseTertiaryLight py-5"
                header={headerText()}
                products={searchProducts()}
              />
            </Show>
          </Show>
          <Show when={relatedProducts().length > 0}>
            <div></div>
            <BrandListing
              class="mt-5 flex flex-col gap-2.5 rounded-2xl bg-baseTertiaryLight py-2.5"
              header={"Related products"}
              products={relatedProducts()}
            />
          </Show>
        </div>
        <Show
          when={data.isLoggedIn && data.clientConfig.showHubbleLogoOnBottom}
        >
          <div class="absolute bottom-0 left-0 right-0">
            <HubbleBranding />
          </div>
        </Show>
      </Show>
    </div>
  );
}

function LoadingAnimation() {
  return <DottedLoader />;
}

function NoResultsFound({
  text,
  isRunning,
}: {
  text: Accessor<string>;
  isRunning: Accessor<boolean>;
}) {
  return (
    <div class="mt-20px flex justify-center px-5 text-h5Bold text-textDark">
      <Switch>
        <Match when={isRunning()}>
          <span class="pl-2 text-normal text-textNormal">
            <LoadingAnimation />
          </span>
        </Match>
        <Match when={text() === ""}>
          <span></span>
        </Match>
        <Match when={true}>
          <div class="mt-4 flex flex-col items-center justify-center">
            <img
              src={noResultsFound}
              class="h-[76px] w-16"
              alt="No Results Found"
            />
            <span class="pl-2 text-center text-normal text-textNormal">
              Please try another keyword <br /> for brand or category
            </span>
          </div>
        </Match>
      </Switch>
    </div>
  );
}

const [cachedCategories, setCachedCategories] = createSignal<Category[]>();

function appendAllBrandsToCatgories(categories: Category[]) {
  const allBrandsObj = {
    id: "",
    metadata: {
      activeBrandsCount: brandsCount,
      description: "All brands",
      promoImageUrl:
        "https://app.myhubble.money/static/misc/all_brands_icon.png",
      thumbnailUrl:
        "https://app.myhubble.money/static/misc/all_brands_icon.png",
      title: "All brands",
    },
    name: "",
  } as Category;

  categories.unshift(allBrandsObj);
  return categories;
}

function CategoryScroll(props: {
  onSelectCategory: (category: string) => void;
  selectedCategory: string;
  onCategoriesLoaded: (categories: string[]) => void;
}) {
  let containerRef: HTMLDivElement | undefined;

  const scrollCategoryIntoView = (button: HTMLButtonElement) => {
    if (!containerRef || !button) return;

    setTimeout(() => {
      const container = containerRef;
      if (!container) return;

      const containerRect = container.getBoundingClientRect();
      const buttonRect = button.getBoundingClientRect();

      const scrollLeft =
        button.offsetLeft - (containerRect.width - buttonRect.width) / 2;

      container.scrollTo({
        left: scrollLeft,
        behavior: "smooth",
      });
    }, 0);
  };

  return (
    <ResourceLoader
      fetch={async () => {
        if (cachedCategories() != undefined) {
          return cachedCategories()!;
        }

        const _categories = await getCategories().then((e) => e.data);
        const categories = appendAllBrandsToCatgories(_categories);

        props.onCategoriesLoaded(categories.map((e) => e.name));

        setCachedCategories(categories);

        return categories;
      }}
      showLoader={false}
    >
      {(categories) => {
        return (
          <div
            ref={(el) => (containerRef = el)}
            class="no-scrollbar flex max-h-[63px] min-h-[63px] select-none flex-row gap-7 overflow-y-hidden overflow-x-scroll rounded-b-2xl bg-baseTertiaryLight px-5"
            role="tablist"
            aria-label="Product Categories"
          >
            <For each={categories}>
              {(category, index) => {
                return (
                  <button
                    ref={(el) => {
                      if (props.selectedCategory == category.name && el) {
                        scrollCategoryIntoView(el);
                      }
                    }}
                    onClick={() => {
                      console.log(category.name);

                      props.onSelectCategory(category.name);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        props.onSelectCategory(category.name);
                      }
                    }}
                    role="tab"
                    tabIndex={0}
                    aria-selected={props.selectedCategory == category.name}
                    aria-label={category.metadata.title}
                    id={`category-tab-${index()}`}
                    class={classNames(
                      "flex cursor-pointer flex-col items-center gap-1.5 pb-0 [transition:0.3s_all]",
                      "focus:outline-2 focus:outline-offset-2 focus:outline-basePrimaryDark"
                    )}
                  >
                    <img
                      class="h-7 object-contain hover:scale-110"
                      src={category.metadata.thumbnailUrl}
                      alt=""
                      aria-hidden="true"
                    />
                    <span class="input-label flex-[1] text-nowrap text-f12Bold text-basePrimaryDark">
                      {category.metadata.title}
                    </span>
                    <div
                      aria-hidden="true"
                      class={classNames(
                        "h-[2.5px]",
                        props.selectedCategory === category.name
                          ? "w-full animate-slideFromBottom rounded-t-sm bg-basePrimaryDark"
                          : ""
                      )}
                    ></div>
                  </button>
                );
              }}
            </For>
          </div>
        );
      }}
    </ResourceLoader>
  );
}

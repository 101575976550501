import {
  Accessor,
  Component,
  createEffect,
  createSignal,
  For,
  onMount,
  Setter,
  Show,
} from "solid-js";
import styles from "./modal.module.scss";
import { classNames } from "../utils/etc";
import { routerLog } from "~/utils/logging";
import attachPopStateListener, {
  detachPopStateListener,
  popStateListener,
} from "~/utils/popstate_listener";
import { EventManager } from "~/data/events";

export enum PageAlignment {
  top,
  bottom,
  left,
  right,
  center,
  topRight,
  topLeft,
  bottomRight,
  bottomLeft,
}
export interface CurrentPage {
  name: HubbleRoute;
  builder: Component<{ page?: CurrentPage }>;
  isModal: boolean;
  isOpen: Accessor<boolean>;
  setIsOpen: Setter<boolean>;
  x?: number;
  y?: number;
  alignment?: PageAlignment;
  offset?: { top?: number; left?: number; right?: number; bottom?: number };
  isContentVisible: Accessor<boolean>;
  setIsContentVisible: Setter<boolean>;
  class?: string;
  enterAnimation?: boolean;
  exitAnimation?: boolean;
  disableCloseButton?: boolean;
  preventClose?: boolean;
  zIndex?: number;
  componentProps?: any;
  eventProps?: Object;
}

export const [pages, setPages] = createSignal<CurrentPage[]>([]);

var isModalPopping = 0;

var pushedWhilePopping: CurrentPage[] = [];

export function pushPage(
  builder: Component<{ page?: CurrentPage }>,
  options: {
    isModal?: boolean;
    event?: MouseEvent;
    alignment?: PageAlignment;
    offset?: {
      top?: number;
      left?: number;
      right?: number;
      bottom?: number;
    };
    class?: string;
    enterAnimation?: boolean;
    exitAnimation?: boolean;
    disableCloseButton?: boolean;
    preventClose?: boolean;
    path?: string;
    pushState?: boolean;
    eventProps?: Object;
  } = {},
  name: HubbleRoute
) {
  routerLog(
    `pushing ${name}. modal popping is ${isModalPopping}. Push history is ${options.pushState}`
  );
  const [isOpen, setIsOpen] = createSignal<boolean>(true);

  var x: number | undefined, y: number | undefined;

  if (options.event) {
    x = options.event.clientX;
    y = options.event.clientY;
  }

  const [isContentVisible, setIsContentVisible] = createSignal<boolean>(true);

  const disableCloseButton =
    (options.disableCloseButton == true && options.isModal == true) ||
    options.isModal != true;

  if (options.pushState ?? true) {
    history.pushState({}, "", "");
  }

  if (isModalPopping > 0) {
    pushedWhilePopping.push({
      builder,
      name: name,
      class: options.class,
      setIsContentVisible,
      isContentVisible,
      isModal: options.isModal == true,
      isOpen,
      setIsOpen,
      x,
      y,
      alignment: options.alignment,
      offset: options.offset,
      enterAnimation: options.enterAnimation,
      exitAnimation: options.exitAnimation,
      disableCloseButton: disableCloseButton,
      preventClose: options.preventClose,
      eventProps: options.eventProps,
    });
    return;
  }

  setPages([
    ...pages(),
    {
      builder,
      class: options.class,
      isContentVisible,
      setIsContentVisible,
      enterAnimation: options.enterAnimation,
      exitAnimation: options.exitAnimation,
      isModal: options.isModal == true,
      isOpen,
      setIsOpen,
      x,
      y,
      alignment: options.alignment,
      offset: options.offset,
      disableCloseButton: disableCloseButton,
      preventClose: options.preventClose,
      name: name,
      eventProps: options.eventProps,
    },
  ]);

  routerLog(`pages are ${pages().map((p) => p.name)}`);
  routerLog(`___________________________________________________________`);
}

export function popPage() {
  routerLog(`doing popPage`);
  if (pages().length == 0) return;
  setPages(pages().slice(0, -1));

  if (pushedWhilePopping.length > 0) {
    setPages([...pages(), ...pushedWhilePopping]);
    return;
  }

  isModalPopping -= 1;
  routerLog(
    `popping done. isModalPopping - ${isModalPopping} pages - ${pages().map((p) => p.name)}`
  );
  routerLog(`___________________________________________________________`);
}

let onPopCallBack = () => {};

onMount(() => {
  window.addEventListener("popstate", (event) => {
    if (event.state) {
      setTimeout(() => {
        onPopCallBack();
        onPopCallBack = () => {};
      }, 260);
    }
  });
});

export function goBack(callback?: () => void) {
  onPopCallBack = callback || (() => {});

  window.history.back();
  routerLog(`window.history.back complete`);
}

export function popWithAnimation() {
  routerLog(`doing popWithAnimation`);
  const modal = pages()[pages().length - 1];

  isModalPopping += 1;
  modal.setIsContentVisible(false);
  setTimeout(() => {
    popPage();
  }, 250);
}

export async function popUntilHome() {
  routerLog(`popping until home`);
  await goBackNTimes(pages().length - 1);
}

export async function goBackNTimes(n: number) {
  routerLog(`going back ${n} times`);
  detachPopStateListener();
  for (let i = 0; i < n; i++) {
    goBack();
    popStateListener();
  }
  await new Promise((resolve) => setTimeout(resolve, 300));
  attachPopStateListener();
}

export function ModalHost({ eventManager }: { eventManager?: EventManager }) {
  // log event effect
  if (eventManager) {
    createEffect<CurrentPage[]>((prev) => {
      const p = pages();
      if ((prev?.length ?? 0) < p.length) {
        const topPage = p[p.length - 1];

        eventManager.sendEvent(
          `visit_${topPage.name?.toLowerCase() as HubbleRoute}`,
          {
            isModal: topPage.isModal,
            ...topPage.eventProps,
          }
        );
      }
      return p;
    });
  }

  // set enterAnimation to false for last page after 250 ms
  createEffect(() => {
    if (pages().length > 0) {
      const lastPage = pages()[pages().length - 1];
      if (lastPage.enterAnimation ?? true) {
        setTimeout(() => {
          lastPage.enterAnimation = false;
        }, 250);
      }
    }
  });

  createEffect(() => {
    if (pages().length > 0) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  });

  const displayPages = () => {
    const indexOfLastPage = pages().findLastIndex(
      (page) =>
        !page.isModal &&
        page.name != "coupons" &&
        page.name != HubbleRoute.PAYMENT_STATUS_POLLING
    );
    return [
      ...pages().filter((page, index) => index >= indexOfLastPage),
    ].filter(Boolean) as CurrentPage[];
  };
  return (
    <For each={displayPages()}>
      {(page, index) => {
        return <ModalLayout modal={page} />;
      }}
    </For>
  );
}

export function ModalLayout(props: { modal: CurrentPage }) {
  const hasPosition = props.modal.x != undefined && props.modal.y != undefined;

  function getProps() {
    const offsetTop = props.modal.offset?.top || 0;
    const offsetLeft = props.modal.offset?.left || 0;
    const offsetRight = props.modal.offset?.right || 0;
    const offsetBottom = props.modal.offset?.bottom || 0;

    switch (props.modal.alignment) {
      case PageAlignment.top:
        return {
          top: `${0 + offsetTop}px`,
          left: `${0 + offsetLeft}px`,
          right: `${offsetRight}px`,
          bottom: "auto",
        };
      case PageAlignment.bottom:
        return {
          top: "auto",
          left: `${0 + offsetLeft}px`,
          right: `${offsetRight}px`,
          bottom: `${0 + offsetBottom}px`,
        };
      case PageAlignment.left:
        return {
          top: `${0 + offsetTop}px`,
          left: `${0 + offsetLeft}px`,
          right: "auto",
          bottom: `${offsetBottom}px`,
        };
      case PageAlignment.right:
        return {
          top: `${0 + offsetTop}px`,
          left: "auto",
          right: `${0 + offsetRight}px`,
          bottom: `${offsetBottom}px`,
        };
      case PageAlignment.topRight:
        return {
          top: `${0 + offsetTop}px`,
          left: "auto",
          right: `${0 + offsetRight}px`,
          bottom: "auto",
        };
      case PageAlignment.topLeft:
        return {
          top: `${0 + offsetTop}px`,
          left: `${0 + offsetLeft}px`,
          right: "auto",
          bottom: "auto",
        };
      case PageAlignment.bottomRight:
        return {
          top: "auto",
          left: "auto",
          right: `${0 + offsetRight}px`,
          bottom: `${0 + offsetBottom}px`,
        };
      case PageAlignment.bottomLeft:
        return {
          top: "auto",
          left: `${0 + offsetLeft}px`,
          right: "auto",
          bottom: `${0 + offsetBottom}px`,
        };
      case PageAlignment.center:
        return {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
        };
    }
  }

  const closeModal = (event: MouseEvent) => {
    event.stopPropagation();
    goBack();
  };
  return (
    <div
      style={{
        "background-color": props.modal.isModal ? "" : "white",
        "user-select": "none",
        "z-index": props.modal.zIndex,
      }}
      class={classNames(
        styles.modalBackdrop,
        props.modal.isContentVisible() ? styles.visible : styles.closed,
        hasPosition ? styles.floating : styles.normal,
        props.modal.isModal ? styles.modal : styles.page,
        props.modal.enterAnimation === false ? styles.noEnter : styles.enter,
        props.modal.exitAnimation === false ? styles.noExit : styles.exit,
        props.modal.class
      )}
      onClick={props.modal.preventClose ? undefined : closeModal}
    >
      <Show when={!props.modal.disableCloseButton}>
        <div class={styles.modalCloseHandle}>
          <CloseButton />
        </div>
      </Show>
      <div
        style={getProps() as any}
        class={styles.modalContent}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {props.modal.builder({ page: props.modal })}
      </div>
    </div>
  );
}

export function CloseButton(props: {
  class?: string;
  onClick?: (event: MouseEvent) => void;
}) {
  return (
    <i
      aria-label="close modal"
      class={"ph ph-x " + classNames(styles.closeButton, props.class)}
      onClick={props.onClick}
    ></i>
  );
}

export enum HubbleRoute {
  // HOME
  HOME = "home",
  HOME_MENU = "home_menu",

  // LOGIN
  COLLECT_PHONE = "collect_phone",
  COLLECT_OTP = "collect_otp",
  COMPLETE_PROFILE = "complete_profile",

  // SHOP
  COUPONS = "coupons",
  CHECKOUT_SUMMARY = "checkout_summary",
  BRAND_L2_SCREEN = "brand_l2",
  VOUCHER_EDIT_MODAL = "voucher_edit_modal",
  VOUCHER_GENERATION_SUCCESS = "voucher_generation_success",
  VOUCHER_GENERATION_FAIL = "voucher_generation_fail",
  VOUCHER_GENERATION_LOADER = "voucher_generation_loader",
  TRANSACTIONS = "transactions",
  TRANSACTION_L2 = "transaction_l2",
  HOW_TO_USE = "how_to_use",
  TNC = "tnc",
  BRAND_UNAVAILABLE = "brand_unavailable_modal",
  WARNING_MODAL = "warning_modal",

  // PAYMENTS
  PAYMENT_LOADER = "payment_loader",
  PAYMENT_OPTIONS = "payment_options",
  INVOICE = "invoice",
  UPI_ID_INPUT = "upi_id_input",
  PAYMENT_STATUS_POLLING = "payment_status_polling",

  COINS_TRANSACTIONS = "coins_transactions",
  WELCOME_MESSAGE = "welcome_message",

  HELP_AND_SUPPORT = "help_and_support",
  COLLECT_PHONE_NUMBER_GIFTING = "collect_phone_number_gifting",
  COLLECT_OTP_GIFTING = "collect_otp_gifting",
  WHATSAPP_SENT = "whatsapp_sent",
}

// declare global {
//   interface MessageHandler {
//     postMessage: (message: string) => void;
//   }
//   interface Window {
//     closeHostView: () => void;
//     hubbleBackButtonPressed: () => void;
//     ReactNativeWebView?: MessageHandler;
//     HubbleClient?: MessageHandler;
//   }
// }

// window.hubbleBackButtonPressed = function () {
//   // Fire an event to the Flutter app.

//   if (pages().length > 1) {
//     popWithAnimation();
//   } else {
//     window.closeHostView();
//   }
// };

// var hostWebview = window.ReactNativeWebView ?? window.HubbleClient;

// window.closeHostView = function () {
//   if (hostWebview) {
//     hostWebview.postMessage(
//       JSON.stringify({
//         type: "closeHostView",
//       })
//     );
//   }
// };

import { For, Match, Show, Switch } from "solid-js";
import {
  RedemptionTransaction,
  getRedemptionTransactions,
} from "../../data/products";
import { ResourceLoader } from "../../widgets/resource_loader";
import { PhosphorIcon } from "../../widgets/icons";
import { BackButton } from "../../widgets/button";
import { TransactionDetail } from "./transaction_detail";
import { HubbleRoute, pushPage } from "../../shared_states/modal";
import { formatDate } from "~/utils/date";
import { EmptyState } from "~/widgets/empty_state";
import { HubbleBranding } from "~/widgets/branding";

type CollatedTransactionStatus =
  | "generating"
  | "generated"
  | "refundIntiaited"
  | "refundFailed"
  | "refunded";

type UIRedemptionTransaction = RedemptionTransaction & {
  collatedStatus: CollatedTransactionStatus;
  formattedDateTime: string;
};

function getTransactionStatus(
  transaction: RedemptionTransaction
): CollatedTransactionStatus {
  switch (transaction.refundStatus) {
    case "INITIATED":
    case "PENDING":
      return "refundIntiaited";
    case "FAILED":
      return "refundFailed";
    case "SUCCESS":
      return "refunded";
    default:
      break;
  }

  switch (transaction.orderStatus) {
    case "INITIALISED":
    case "PROCESSING":
      return "generating";
    default:
      return "generated";
  }
}

export function RedemptionTransactions() {
  return (
    <ResourceLoader
      fetch={() =>
        getRedemptionTransactions()
          .then((e) => e.data)
          .then((transactions) => {
            return transactions.map((transaction: RedemptionTransaction) => {
              return {
                ...transaction,
                collatedStatus: getTransactionStatus(transaction),
                formattedDateTime: formatDate(new Date(transaction.dateTime)),
              } as UIRedemptionTransaction;
            });
          })
      }
    >
      {(transactions) => {
        return (
          <div class="absolute bottom-0 left-0 right-0 top-0 flex flex-col bg-white">
            <span
              class={"title-appbar p-5 text-center text-bold text-textDark"}
            >
              Transactions
            </span>
            <BackButton />
            <div
              class={`no-scrollbar flex flex-col overflow-x-hidden overflow-y-scroll pb-[50px]`}
            >
              <Show
                when={transactions.length > 0}
                fallback={
                  <EmptyState
                    primaryText="No transactions yet"
                    secondaryText="Brand card you purchase will appear here."
                    classList="h-screen"
                  />
                }
              >
                <For each={transactions}>
                  {(transaction) => {
                    return (
                      <div
                        class="flex cursor-pointer items-center gap-3 px-5 py-0"
                        onClick={() => {
                          pushPage(
                            () => (
                              <TransactionDetail
                                transactionId={transaction.id}
                              />
                            ),
                            undefined,
                            HubbleRoute.TRANSACTION_L2
                          );
                        }}
                      >
                        <img
                          class="h-[52px] w-[52px] rounded-[30px] object-cover"
                          src={transaction.logoImageUrl}
                        />
                        <div class="flex flex-[1] flex-col gap-2 border-b border-baseTertiaryMedium px-0 py-5">
                          <span class="title-primary flex flex-row justify-between text-bold text-textDark">
                            {transaction.description}{" "}
                            <PhosphorIcon
                              name="caret-right"
                              class="text-[16px] text-textNormal"
                              size="bold"
                            />
                          </span>
                          <span class="body-small flex flex-row justify-between text-medium text-textNormal">
                            {transaction.formattedDateTime}
                            <TransactionStatusMessage
                              transaction={transaction}
                            />
                          </span>
                        </div>
                      </div>
                    );
                  }}
                </For>
              </Show>
            </div>

            <div class="absolute bottom-0 left-0 right-0">
              <HubbleBranding />
            </div>
          </div>
        );
      }}
    </ResourceLoader>
  );
}

function TransactionStatusMessage(props: {
  transaction: UIRedemptionTransaction;
}) {
  return (
    <span class="text-mediumBold">
      <Switch>
        <Match when={props.transaction.collatedStatus == "generating"}>
          <span class="text-[#274ff5]">Generating ... </span>
        </Match>

        <Match when={props.transaction.collatedStatus == "refundIntiaited"}>
          <span class="flex items-center gap-1 text-successDark">
            Refund Initiated{" "}
          </span>
        </Match>

        <Match when={props.transaction.collatedStatus == "refunded"}>
          <span class="flex items-center gap-1 text-successDark">
            <PhosphorIcon name="check" size="bold" class="text-[16px]" />{" "}
            Refunded{" "}
          </span>
        </Match>

        <Match when={props.transaction.collatedStatus == "refundFailed"}>
          <span class="text-errorDark"> Refund Failed </span>
        </Match>
      </Switch>
    </span>
  );
}

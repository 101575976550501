import { For, Show, createSignal } from "solid-js";
import { VoucherCart } from "./voucher";
import { classNames } from "../../utils/etc";

function VariantItem(props: {
  name: string;
  value: string;
  isSelected: boolean;
  onClick: () => void;
}) {
  return (
    <div
      class="flex w-full cursor-pointer flex-row items-start justify-between"
      onClick={props.onClick}
    >
      <span class="flex items-start gap-1 text-bolder text-basePrimaryMedium">
        <Show
          when={props.isSelected}
          fallback={<i class="ph ph-circle text-[20px]"></i>}
        >
          <i class="ph-fill ph-check-circle text-[20px]"></i>
        </Show>
        {props.name}
      </span>
      <span class="text-bolder text-basePrimaryMedium">{props.value}</span>
    </div>
  );
}

export function ProductVariantSelection(props: {
  cart: VoucherCart;
  onChange: (value: {
    variantId: string;
    name: string;
    description: string;
    amount: number;
  }) => void;
  class?: string;
}) {
  const _variants = props.cart.voucherProduct!.variantDetailedResponse!.map(
    (variant) => {
      return {
        id: variant.variantId,
        name: variant.text,
        value: variant.amount,
        description: variant.subtext,
      };
    }
  );

  const [selectedVariant, setSelectedVariant] = createSignal<string>(
    props.cart.variantId || _variants[0].id
  );

  return (
    <div
      class={classNames(
        "box-border flex w-full flex-col gap-5 p-5",
        props.class
      )}
    >
      <For each={_variants}>
        {(variant) => (
          <VariantItem
            name={variant.name}
            value={variant.value.toString()}
            isSelected={selectedVariant() == variant.id}
            onClick={() => {
              setSelectedVariant(variant.id);

              props.onChange({
                variantId: variant.id,
                name: variant.name,
                description: variant.description,
                amount: variant.value,
              });
            }}
          />
        )}
      </For>
    </div>
  );
}

import {
  SimulatedTransactionDetails,
  VoucherProduct,
  VoucherType,
} from "~/server/types/brand";
import { Coupon } from "../../data/coupons";
import {
  dryRunTransaction,
  getCoupons,
  getVoucherProduct,
} from "../../data/products";

export interface BrandLandingPageDetails {
  enabled: boolean;
  logoUrl: string;
  coverImageUrl: string;
  description: string;
  heading: string;
  cardBenefits: string[];
  websiteUrl: string;
  doesDiscountWorkOverBrandOffers: boolean;
  shouldDisplayInDarkTheme: boolean;
}

export interface VoucherProductProviderMetadata {
  onlySingleDenominationAllowed: boolean;
}

export const getVoucherType = (product: VoucherProduct): VoucherType => {
  if (product.variantDetailedResponse) {
    return "variant";
  } else if (
    product.amountConditions.denominations != null &&
    product.amountConditions.denominations.length > 0
  ) {
    ("denominations");
    return "denominations";
  } else {
    return "freeValue";
  }
};

export interface DenominationItem {
  denomination: number;
  display: string;
  quantity: number;
}

export enum VoucherCartState {
  valid,
  errored,
  processing,
}

export interface VoucherCart {
  voucherType: VoucherType;
  amount: number;
  variantId?: string;
  denominations?: DenominationItem[];
  displayText?: string;
  displayValue?: string;
  dryRunTransaction?: SimulatedTransactionDetails[];
  voucherProductId: string;
  cartMessage?: string;
  cartState?: VoucherCartState;
  voucherProduct?: VoucherProduct;
  coupons?: Coupon[];
  couponCode?: string;
  useCoins?: boolean;
  isCoinsOnly?: boolean;
}

export function buildDefaultVoucherCart(voucher: VoucherProduct): VoucherCart {
  const isCoinsOnly = voucher.restrictions.paymentSource == "COINS_ONLY";

  if (voucher.voucherProductType == "denominations") {
    const denominations =
      voucher.amountConditions.defaultDenominationDetails.map(
        (denomination) => {
          return {
            denomination: denomination.denomination,
            display: denomination.denomination.toString(),
            quantity: denomination.quantity,
          };
        }
      );

    const amount = denominations.reduce(
      (acc, denomination) =>
        acc + denomination.denomination * denomination.quantity,
      0
    );

    const count = denominations.reduce(
      (acc, denomination) => acc + denomination.quantity,
      0
    );

    const displayText = (count > 0 ? count : "") + " " + (count > 1 ? "Cards" : "Card") + " worth";

    return {
      voucherType: voucher.voucherProductType,
      amount,
      denominations,
      displayText,
      voucherProductId: voucher.externalId,
      voucherProduct: voucher,
      isCoinsOnly,
      useCoins: isCoinsOnly,
    };
  }

  if (voucher.voucherProductType == "variant") {
    const variant = voucher.variantDetailedResponse!.find(
      (variant) => variant.isDefault
    )!;
    return {
      voucherType: voucher.voucherProductType,
      amount: variant.amount,
      variantId: variant.variantId,
      displayText: variant.subtext,
      displayValue: variant.text,
      voucherProductId: voucher.externalId,
      voucherProduct: voucher,
      useCoins: isCoinsOnly,
      isCoinsOnly,
    };
  }

  return {
    voucherType: voucher.voucherProductType,
    amount: voucher.amountConditions.defaultAmount,
    displayText: "Card worth",
    voucherProductId: voucher.externalId,
    voucherProduct: voucher,
    isCoinsOnly,
    useCoins: isCoinsOnly,
  };
}

export async function refreshCart(
  cart: VoucherCart,
  updateCoupons: boolean = false,
  isLoggedIn: boolean
): Promise<VoucherCart> {
  if (!cart.voucherProduct) {
    const voucher = await getVoucherProduct(cart.voucherProductId);

    cart = {
      ...cart,
      voucherProduct: voucher,
    };
  }

  if (updateCoupons && !cart.coupons && isLoggedIn) {
    const coupons = await getCoupons({
      amount: cart.amount,
      voucherProductId: cart.voucherProductId,
    });

    cart = {
      ...cart,
      coupons: coupons.data,
    };
  }

  return dryRunTransaction({
    denominationDetails: cart.denominations?.map((denomination) => {
      return {
        denomination: denomination.denomination,
        quantity: denomination.quantity,
      };
    }),
    variantId: cart.variantId,
    voucherProductId: cart.voucherProductId,
    amount: cart.amount,
    couponCode: cart.couponCode,
    consumeCoins: cart.useCoins,
  })
    .then((result) => {
      return {
        ...cart,
        dryRunTransaction: result.data,
        cartState: VoucherCartState.valid,
        cartMessage: "",
      };
    })
    .catch((error) => {
      return {
        ...cart,
        cartState: VoucherCartState.errored,
        cartMessage: error.message,
      };
    });
}

import { Cookie, FetchOptions, Header, QueryParam } from "~/types";
import { Repo } from "./repo";
import { APIError } from "~/utils/fetch";
import { hubbleFetch } from "./base";
import { getOS } from "~/utils/platform";
import { getModeFromQueryParamsAndCookies, newRequestId } from "~/utils/common";
import { getCookie } from "~/utils/client_cookie";
import { defaultHeaders } from "~/data/auth";
import { RequestData } from "./repo_base";

class ClientRepo extends Repo {
  getCompleteHeaders(
    headers?: Record<string, string>,
    options?: FetchOptions
  ): Record<string, string> {
    let completeHeaders: Record<string, string> = {
      ...headers,
      [Header.ContentType]: "application/json",
      [Header.AppVersion]: "10000",
      [Header.DeviceInfo]: "WEB",
      [Header.DeviceOs]: "WEB",
      [Header.HostOs]: getOS() ?? "UNKNOWN",
      [Header.RequestId]: newRequestId(),
    };

    if (!options?.isUnauthenticated) {
      let sid =
        getCookie(Cookie.SessionId) ||
        defaultHeaders()[Header.SessionId] ||
        localStorage.getItem("sessionId");
      if (sid) {
        completeHeaders = {
          [Header.SessionId]: sid,
          ...completeHeaders,
        };
      }
    }

    const searchParams = new URLSearchParams(window.location.search);
    const clientId = searchParams.get(QueryParam.ClientId);
    const clientSecret = searchParams.get(QueryParam.ClientSecret);
    if (clientId) {
      completeHeaders = {
        ...completeHeaders,
        [Header.ClientId]: clientId as string,
        [Header.ClientSecret]: clientSecret as string,
      };
    }

    return completeHeaders;
  }
  async fetch<T>(request: RequestData): Promise<T> {
    const completeHeaders = this.getCompleteHeaders(
      request.headers,
      request.options
    );

    if (request.options?.isUnauthenticated) {
      delete completeHeaders[Header.SessionId];
    }

    try {
      const response = await hubbleFetch(
        request.url,
        request.method,
        completeHeaders,
        request.body,
        request.options
      );

      const mode = getModeFromQueryParamsAndCookies();

      if (!response.ok) {
        if (response.status === 401) {
          if (request.options?.throwAuthError) {
            throw new APIError("Your session is expired", 401);
          } else if (
            window.location.href.includes("gift-box") ||
            window.location.href.includes("pluto") ||
            window.location.href.includes("smart-gc")
          ) {
            throw new APIError("Your session is expired", 401);
          } else if (mode == "sdk") {
            throw new APIError("Your session is expired", 401);
          } else {
            window.location.href = `/logout`;
          }
        } else if (response.status === 429) {
          throw new APIError(
            "Limit is exceeded. Please try after sometime.",
            429
          );
        } else {
          try {
            const errorResponse = await response.json();
            throw new APIError(
              errorResponse.subErrors.length > 0
                ? errorResponse.subErrors[0].message
                : errorResponse.message,
              400,
              errorResponse.subErrors,
              errorResponse.message
            );
          } catch (e) {
            // this is to catch response.json() parse failure
            // If there is already an API error thrown, we will simply rethrow it
            if (e instanceof APIError) throw e;

            throw new APIError(
              "Unknown error occurred. Please try again.",
              400
            );
          }
        }
      }
      if (request.responseTypeNonJson) {
        return response as T;
      }

      if (response.status !== 204) {
        const responseJson = await response.json();
        return responseJson as T;
      }

      return null as T;
    } catch (e) {
      if (e instanceof APIError) {
        console.trace(
          `client-fetch - API error ${request.url}, reqId ${completeHeaders[Header.RequestId]}, error ${e.message} code ${e.code}`
        );
      } else {
        console.trace(
          `client-fetch - got exception while fetching ${request.url}, reqId ${completeHeaders[Header.RequestId]}`,
          e
        );
      }
      throw e;
    }
  }
}

export const clientRepo = new ClientRepo();

import { Accessor, Setter, createEffect, createSignal } from "solid-js";
import { clientRepo } from "~/server/apis/client_repo";
import { PaginationRequest, Product } from "~/server/types/search";
import { SearchBar } from "./search_bar";
import { SearchResults } from "./search_results";
import { captureErrorInSentry } from "~/utils/third_party/sentry";

export function Search(props: {
  setIsSearchActive: Setter<boolean>;
  isSearchActive: Accessor<boolean>;
  setClearSearch: Setter<boolean>;
  clearSearch: Accessor<boolean>;
  allBrands: Accessor<Product[]>;
  isProcurment?: boolean;
  openDenominationModal?: (product: Product) => void;
  title?: string;
}) {
  const [searchQuery, setSearchQuery] = createSignal<string>("");

  const [searchResult, setSearchResult] = createSignal<Product[]>(
    props.allBrands()
  );

  const [noResultsFound, setNoResultsFound] = createSignal<boolean>(false);
  const [isFetchingResults, setIsFetchingResults] =
    createSignal<boolean>(false);

  createEffect(() => {
    if (props.clearSearch()) {
      onClear();
    }
  });

  createEffect(() => {
    if (
      searchResult().length == 0 &&
      !noResultsFound() &&
      props.allBrands().length > 0 &&
      searchQuery() == ""
    ) {
      setSearchResult(props.allBrands());
    }
  });

  async function onSearchQueryChange(e: Event) {
    const inputElement = e.target as HTMLInputElement;
    setSearchQuery(inputElement.value);
    if (searchQuery() != "") {
      setIsFetchingResults(true);
      const resultData = await getSearchResults(searchQuery(), {
        filterOutInactive: false,
      });
      setIsFetchingResults(false);
      if (resultData.length == 0) {
        setNoResultsFound(true);
      } else {
        setNoResultsFound(false);
      }
      props.setIsSearchActive(true);
      setSearchResult(resultData);
    } else {
      setSearchResult(props.allBrands());
    }
  }

  function onClear() {
    setSearchQuery("");
    setSearchResult(props.allBrands());
    setNoResultsFound(false);
    props.setIsSearchActive(false);
    props.setClearSearch(false);
  }

  return (
    <div
      class={`w-full cursor-pointer items-center justify-center ${props.isProcurment ? "px-0 sm:px-4 lg:w-[294px]" : "px-4 sm:w-[449px]  sm:px-0 lg:w-[494px]"}`}
    >
      <div class="">
        <SearchBar
          onInput={onSearchQueryChange}
          onClear={onClear}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          isSearchActive={props.isSearchActive}
          setIsSearchActive={props.setIsSearchActive}
          isProcurment={props.isProcurment}
          title={props.title}
        />
      </div>

      <div class="relative">
        <div
          class={`noScrollbar absolute  top-1  max-h-[400px] w-full overflow-y-scroll rounded-2xl border border-baseTertiaryDark bg-baseTertiaryLight darkTheme:bg-baseTertiaryDark  sm:w-[449px]   sm:px-0
          ${props.isSearchActive() ? "" : "border-0"}
          ${props.isProcurment ? "lg:w-[294px]" : "lg:w-[494px] "}
          `}
        >
          <SearchResults
            result={searchResult()}
            isSearchActive={props.isSearchActive}
            noResultsFound={noResultsFound()}
            onBrandClick={() => {
              onClear();
            }}
            isFetchingResults={isFetchingResults}
            isProcurment={props.isProcurment}
            openDenominationModal={props.openDenominationModal}
          />
        </div>
      </div>
    </div>
  );
}

export async function getSearchResults(
  query: string,
  options: { filterOutInactive: boolean },
  pagination?: PaginationRequest
) {
  try {
    const result = await clientRepo.getProductSearch({
      query,
      pagination,
    });
    const resultData: Product[] = [];

    result.data.forEach((section) => {
      section.products.forEach((product) => {
        if (product.voucherProduct.status === "ACTIVE")
          resultData.push(product);
        else if (!options.filterOutInactive) resultData.push(product);
      });
    });

    return resultData;
  } catch (e: any) {
    captureErrorInSentry(e);
    return [];
  }
}

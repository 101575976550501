import { HubbleFullSvg } from "~/assets/assets";
import { classNames } from "~/utils/etc";

export function HubbleBranding(props: {
  verticalOrientation?: boolean | false;
  class?: string;
}) {
  if (props.verticalOrientation) {
    return (
      <div
        class={classNames(
          props.class ?? "",
          "flex w-[280px] flex-col items-center justify-center  text-[8px] font-medium"
        )}
      >
        POWERED BY
        <div class="h-[2px]" />
        <HubbleFullSvg class="h-[10px]" />
      </div>
    );
  }

  return (
    <div
      class={classNames(
        props.class ?? "py-[5px]",
        "flex flex-row items-center justify-center border-t-[1px] bg-baseTertiaryLight darkTheme:border-t-[#212427]  "
      )}
    >
      <BrandingMinimal />
    </div>
  );
}

export function BrandingMinimal(props: { styles?: string }) {
  return (
    <>
      <span class={`text-f12Bold   darkTheme:text-[#ABABAB]  ${props.styles}`}>
        Powered by
      </span>
      <div class="w-[8px]" />
      <div class="h-[2px]" />
      <HubbleFullSvg
        class={`h-[13px] darkTheme:fill-[#ABABAB] ${props.styles}`}
      />
    </>
  );
}

export function BrandingRow(props: { styles?: string; class?: string }) {
  return (
    <div class={props.class}>
      <div class="flex flex-row items-center justify-center bg-baseTertiaryLight py-2">
        <span class={`pr-1  text-f12Bold ${props.styles}`}>Powered by</span>
        <HubbleFullSvg class="h-[14px]" />
      </div>
    </div>
  );
}

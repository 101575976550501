import { classNames } from "~/utils/etc";
import { Match, Show, Switch } from "solid-js";
import { toIndianNumber, toRupees } from "~/utils/number";
import { SimulatedTransactionDetails } from "~/server/types/brand";
import { useClassicContext } from "~/data/classic_context";

type CoinStripProps = {
  isCoinsOnly: boolean;
  coinIconUrl: string;
  primaryTransaction: () => SimulatedTransactionDetails;
};

export function TotalAndDiscountedAmount(props: CoinStripProps) {
  const { data, isClientDonationType } = useClassicContext();

  const v4Home = data.isLoggedIn ? data.v4HomePage : null;

  const { isCoinsOnly, coinIconUrl, primaryTransaction } = props;

  return (
    <div
      class={classNames(
        "relative flex flex-col items-center gap-1 p-5",
        isCoinsOnly ? "" : ""
      )}
    >
      <Switch>
        <Match when={isCoinsOnly}>
          <span class="text-mediumSemiBold text-textDark">Get now using</span>
          <span
            class={classNames(
              "flex text-center text-h2 text-successDark",
              "flex flex-row items-center gap-1 text-h2 text-[#eda10c]"
            )}
          >
            <img src={coinIconUrl} class="h-[28px] w-[28px]" />{" "}
            {toIndianNumber(primaryTransaction().totalRequiredCoins)}
          </span>
          <Show
            when={data.isLoggedIn && primaryTransaction().requiredDeposit == 0}
          >
            <span class={"text-medium text-textNormal"}>
              Balance: {toIndianNumber(v4Home!.coins.totalAvailable)}
            </span>
          </Show>
        </Match>
        <Match when={isClientDonationType()}>
          <span class="text-center text-mediumSemiBold text-successDark">
            Donating{" "}
            {primaryTransaction().discountDetails.hubbleBaseDiscount.percentage}
            %
          </span>
          <span class="flex text-center text-h2 text-successDark">
            {toRupees(
              primaryTransaction().discountDetails.hubbleBaseDiscount.amount
            )}
          </span>
        </Match>
        <Match when={true}>
          <span class="title-secondary text-center text-mediumSemiBold text-successDark">
            Get for{" "}
            {primaryTransaction().discountDetails.totalDiscount.percentage}% off
          </span>
          <span class="title-display flex text-center text-h2 text-successDark">
            {toRupees(primaryTransaction().dueAmount)}
          </span>
          <span class="body-small text-center text-normal text-textDark line-through">
            {toRupees(primaryTransaction().redemptionAmount)}
          </span>
        </Match>
      </Switch>
    </div>
  );
}

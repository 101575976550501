import { Accessor, JSX, Setter, Show } from "solid-js";
import { zIndexes } from "~/z_indexes";
import { CoinsPillOrBranding } from "../brand_l2/coinspill_or_branding";
import { Mode } from "~/types";
import { PhosphorIcon } from "~/widgets/icons";
import { HeaderMenu } from "../header_menu";
import { A } from "@solidjs/router";
import { openIntercom } from "~/utils/third_party/intercom";
import { axisLogo, HubbleFullSvg } from "~/assets/assets";

type AxisHeaderProps = {
  userName?: string;
  mode: Mode;
  isLoggedIn: () => boolean;
  coins?: Accessor<number>;
  showCoins?: Accessor<boolean>;
  setRefreshCoins?: Setter<boolean>;
  isSearchActive?: Accessor<boolean>;
  setIsSearchActive?: Setter<boolean>;
  isDropdownOpen?: Accessor<boolean>;
  setIsDropdownOpen?: Setter<boolean>;
  latestCoinsBalance?: Accessor<number | undefined>;
  setRefreshLatestBalance?: Setter<boolean>;
  buildSearchBar?: () => JSX.Element;
};

export const AxisHeader = ({
  userName,
  isLoggedIn,
  mode,
  coins,
  showCoins,
  setRefreshCoins,
  isSearchActive,
  setIsSearchActive,
  isDropdownOpen,
  setIsDropdownOpen,
  latestCoinsBalance,
  setRefreshLatestBalance,
  buildSearchBar,
}: AxisHeaderProps) => {
  return (
    <div
      class={`${isLoggedIn() ? "sticky" : "absolute"} top-0 h-[80px] w-full  bg-baseTertiaryLight pb-2 sm:h-[96px]`}
      style={{
        "z-index": zIndexes.header,
      }}
    >
      <div
        class={`absolute left-0 right-0  h-screen w-full bg-baseDark transition-all duration-300 darkTheme:bg-baseLight md:top-24 ${isSearchActive?.() ? "opacity-40" : "pointer-events-none opacity-0"} ${isSearchActive?.() ? "top-28" : "top-24"}`}
      />
      <div class="flex h-full w-full flex-row">
        {/* left side space filler div */}
        <div class="flex h-full w-full flex-1 bg-[#AF275F]"></div>

        {/* main content div, will be center aligned, with some max width */}
        <div class="relative flex w-full max-w-[1200px] flex-row ">
          {/* first half of the strip */}
          <div class="flex h-full w-[40%] flex-row items-center justify-start gap-1  bg-[#AF275F] pl-4 sm:w-[20%] sm:justify-end sm:pr-[60px]">
            <div class="md:hidden">
              <DropDown />
            </div>
            <A href="/" class="">
              <img src={axisLogo} alt="axis bank logo" class="h-6 sm:h-7" />
            </A>
          </div>

          {/* second half of the strip */}
          <div class="flex h-full flex-1 flex-col items-start ">
            <div class="h-[45%] w-full bg-[#AF275F]">
              <div class="hidden sm:block">
                <DesktopButtons />
              </div>
            </div>
            <div class="h-[55%] ">
              <AxisStripBottomCurveSvg />
            </div>
          </div>

          {/* absolute - search bar and coins */}
          <Show when={isLoggedIn()}>
            <SearchBarAndCoins />
          </Show>
        </div>

        {/* right side space filler div */}
        <div class="flex h-[45%] w-full flex-1 bg-[#AF275F]"></div>
      </div>

      {/* search results for mobile view */}
      <div class="absolute mt-1 flex w-full flex-row items-center justify-center bg-baseTertiaryLight md:hidden">
        <Show when={isSearchActive?.()}>{buildSearchBar?.()}</Show>
      </div>

      {/* overlay behind dropwown, search results, will optimise later */}
      {/* <div
        class={`pointer-events-none fixed  inset-0  w-full bg-baseDark opacity-0 transition-all duration-300 md:top-28 ${isSearchActive?.() ? "top-32" : "top-20"}`}
        classList={{
          "opacity-40": isDropdownOpen?.() || isSearchActive?.(),
        }}
      ></div> */}
    </div>
  );
  function DropDown() {
    return (
      <>
        <div
          class="cursor-pointer"
          onClick={() => {
            setIsDropdownOpen?.(!isDropdownOpen?.());
          }}
        >
          <Show
            when={isDropdownOpen?.()}
            fallback={
              <PhosphorIcon
                name="list"
                fontSize={28}
                size={"bold"}
                class="text-white"
              />
            }
          >
            <PhosphorIcon
              name="x"
              fontSize={28}
              size={"bold"}
              class="text-white"
            />
          </Show>
        </div>

        <HeaderMenu
          isDropdownOpen={isDropdownOpen!}
          setIsDropdownOpen={setIsDropdownOpen!}
          isLoggedIn={isLoggedIn()}
          mode={mode}
          isClientAxis={() => true}
        />
      </>
    );
  }

  function DesktopButtons() {
    return (
      <div class="flex justify-end gap-5 py-1 md:mr-8 xl:mr-0 xl:py-3">
        <p
          class="cursor-pointer text-mediumBold text-white"
          onClick={() => {
            openIntercom();
          }}
        >
          Contact support
        </p>
        <A
          href="https://www.myhubble.money/axisbank/terms-and-conditions"
          target="_blank"
        >
          <p class="cursor-pointer text-mediumBold text-white">
            Terms & conditions
          </p>
        </A>
        <Show when={isLoggedIn()}>
          <A href="/profile">
            <p class="cursor-pointer text-mediumBold text-white">
              My gift cards
            </p>
          </A>
          <A href="/logout">
            <p class="cursor-pointer text-mediumBold text-white">Logout</p>
          </A>
        </Show>
      </div>
    );
  }

  function SearchBarAndCoins() {
    return (
      <>
        <div class="hidden sm:block ">
          <div class="absolute bottom-0 left-1/2 -translate-x-1/2 transform">
            {buildSearchBar?.()}
          </div>
          <div
            class="absolute bottom-0 right-0"
            classList={{
              "mt-1": showCoins?.() ?? false,
            }}
          >
            {buildCoinsPillOrBranding()}
          </div>
        </div>
        <div class="block sm:hidden">
          <div class="absolute bottom-0 right-4 flex flex-row">
            {buildCoinsPillOrBranding()}
            <Show when={showCoins?.()}>
              <div
                class="ml-2 flex h-8 w-8 items-center justify-center rounded-full border border-baseTertiaryDark align-middle"
                onClick={() => {
                  setIsDropdownOpen?.(false);
                  setIsSearchActive?.(!isSearchActive?.());
                }}
              >
                <PhosphorIcon
                  name="magnifying-glass"
                  fontSize={14}
                  class="text-textNormal"
                  size="bold"
                />
              </div>
            </Show>
          </div>
        </div>
      </>
    );
  }

  function buildCoinsPillOrBranding() {
    return (
      <CoinsPillOrBranding
        mode={mode}
        showCoins={showCoins}
        userName={userName}
        setRefreshCoins={setRefreshLatestBalance}
        setRefreshLatestBalance={setRefreshLatestBalance!}
        latestCoinsBalance={latestCoinsBalance!}
        isRedeemCodeEnabled={false}
        coins={coins}
        showBranding={true}
        showVerticalBranding={false}
        isClientAxis={true}
        buildBranding={buildBranding}
      />
    );
  }

  function buildBranding() {
    return (
      <div class="flex flex-row items-center justify-center pt-4 sm:flex-col sm:items-end sm:pt-0">
        <span
          class={` text-[10px] font-semibold text-[#7c7c7c] sm:text-[8.12px] `}
        >
          Powered by
        </span>
        <div class="w-[8px]" />
        <div class="h-[2px]" />
        <HubbleFullSvg class="h-[11.76px]" fill="#7c7c7c" />
      </div>
    );
  }

  function AxisStripBottomCurveSvg() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 48"
        class="h-full w-auto"
        fill="none"
      >
        <path
          d="M0.651883 48H0V0H50C42.9619 0 36.538 4.00744 33.4433 10.3286L21.3091 35.1134C17.4479 43.0001 9.43301 48 0.651883 48Z"
          fill="#AF275F"
        />
      </svg>
    );
  }
};

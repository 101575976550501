import { config } from "~/data/config";
import { FetchOptions } from "~/types";
import { RepoBase } from "./repo_base";
import { Header, Optional } from "../../types";

// Types from brand
import {
  SimulatedTransactionResponse,
  SimulateTransactionRequest,
  BrandHowToUseStepsResponse,
  GeneratePartnerOTPResponse,
  GeneratePartnerOTPBody,
  GiftCardBalance,
  VerifyPartnerOTPResponse,
  VoucherProduct,
  BrandExtraMetadata,
  VerifyCodeResponse,
  VerifyCodeBody,
  ClientMinimalConfig,
  GenerateProcurementOTPResponse,
} from "../types/brand";

// Types from order
import {
  OrderDetails,
  PaymentOrderDetails,
  RedemptionParams,
  RedemptionResponse,
  CoinsSummaryResponse,
  SquidOrderDetailResponse,
  SquidOrderInitiateRequest,
  SquidOrderInitiateResponse,
  SquidProformaInvoiceGenerationProps,
  SquidProformaInvoiceGenerationResponse,
  SquidSimulateProps,
  SquidSimulateResponse,
  ShareableCoupon,
} from "../types/order";

// Types from search
import { PaginationRequest, SearchResults } from "../types/search";

// Types from auth
import {
  SquidOnboarding,
  VerificationPlatform,
  VerifySquidOnboardingResponse,
} from "../types/auth";

// Types from gift
import {
  GetGiftBoxV3Response,
  GiftAuthMethod,
  GiftBoxDeliveryResponse,
  GiftBoxSendOtpResponse as GiftSendOtpResponse,
  GiftDetailsMinimalResponse,
  GiftVoucherDetailsResponse,
  VerifyOtpResponseV3 as GiftVerifyOtpResponseV3,
  PlutoGiftDetails,
  GiftBoxMetadata,
} from "../types/gift";

// Types from redeem_code
import { RedeemCodeResponse } from "../types/redeem_code";

// Mock responses
import {
  getMockGetGiftVoucherDetailsResponse,
  getMockGiftDetailsMinimalResponse,
} from "./mock_response";

// Types from user
import { UserProfile } from "../types/user";

// Types from pluto
import {
  ChatDetails,
  Credits,
  GiftCardDetails,
  GiftCardOccasion,
  GiftCardSelection,
  Gifts,
  InitiatePayment,
} from "../types/pluto";

// Types from transaction
import { CoinsTransactionsResponse } from "~/data/transaction";

export abstract class Repo extends RepoBase {
  async simulate(
    request: SimulateTransactionRequest
  ): Promise<SimulatedTransactionResponse> {
    const url = `${config.baseUrl}/v3/redemptions/simulate`;
    return await this.post<SimulatedTransactionResponse>({
      url: url,
      body: request,
    });
  }

  async getPaymentOrderDetails(
    orderId: string,
    headers?: Record<string, string>
  ): Promise<PaymentOrderDetails> {
    const path = `v1/orders/${orderId}`;
    return this.get<PaymentOrderDetails>({
      url: `${config.baseUrl}/${path}`,
      headers: headers,
    });
  }

  async getStoreOrderDetails(
    orderId: string,
    headers?: Record<string, string>
  ): Promise<OrderDetails> {
    const path = `v1/store/orders/${orderId}`;
    return this.get<OrderDetails>({
      url: `${config.baseUrl}/${path}`,
      headers: headers,
    });
  }

  async initiateRedemption(
    params: RedemptionParams,
    headers?: Record<string, string>
  ): Promise<RedemptionResponse> {
    const path = "v2/redemptions/provider/pg/initiate";
    return this.post<RedemptionResponse>({
      url: `${config.baseUrl}/${path}`,
      body: params,
      headers: headers,
    });
  }

  async verifyUser(visitorId: string, platform: VerificationPlatform) {
    const path = "v1/user/verify";
    return this.post<void>({
      url: `${config.baseUrl}/${path}`,
      body: {
        id: visitorId,
        platform: platform,
      },
    });
  }

  async getProductSearch(props: {
    query?: string;
    category?: string;
    pagination?: PaginationRequest;
    headers?: Record<string, string>;
  }): Promise<SearchResults> {
    const path = "v3/store/products/search";

    const queryString = props.category
      ? `category=${props.category}`
      : props.pagination
        ? `q=${props.query}&limit=${props.pagination.limit}&pageNo=${props.pagination.pageNo}`
        : `q=${props.query}`;
    return this.get<SearchResults>({
      url: `${config.baseUrl}/${path}?${queryString}`,
      headers: props.headers,
    });
  }

  async getBrandHowToUseSteps(
    brandId: string
  ): Promise<BrandHowToUseStepsResponse> {
    const path = `v1/store/products/voucher-product/${brandId}/how-to-redeem-details`;
    return this.get<BrandHowToUseStepsResponse>({
      url: `${config.baseUrl}/${path}`,
    });
  }

  async toggleVoucherStatus(voucherId: string): Promise<void> {
    const path = `v1/store/voucher/${voucherId}/toggle-status`;
    return this.post<void>({
      url: `${config.baseUrl}/${path}`,
      body: null,
    });
  }

  async getGiftCardBalance(
    token: string,
    cardNumber: string,
    cardPin: string
  ): Promise<GiftCardBalance> {
    const queryString = `cardNumber=${cardNumber}&cardPin=${cardPin}&token=${token}`;

    const url = `${config.baseUrl}/public/utils/card-balance?${queryString}`;
    return this.get<GiftCardBalance>({
      url: url,
    });
  }

  async getBrand(brandIdOrBrandKey: string): Promise<VoucherProduct> {
    const url = `${config.baseUrl}/v1/store/products/voucher-product/${brandIdOrBrandKey}`;
    return this.get<VoucherProduct>({
      url: url,
    });
  }

  async verifyPartnerCode(
    headers: {
      clientId: string;
      clientSecret: string;
    },
    body: VerifyCodeBody,
    options?: FetchOptions
  ): Promise<VerifyCodeResponse> {
    const { clientId, clientSecret } = headers;
    const { code } = body;

    const url = `${config.baseUrl}/v1/auth/partner/code/verify`;
    return this.post<VerifyCodeResponse>({
      url: url,
      body: {
        code,
      },
      headers: {
        [Header.ClientId]: clientId,
        [Header.ClientSecret]: clientSecret,
      },
      options: options,
    });
  }

  async generatePartnerOTP(
    headers: {
      clientId: string;
      clientSecret: string;
    },
    body: GeneratePartnerOTPBody,
    options?: FetchOptions
  ): Promise<GeneratePartnerOTPResponse> {
    const { clientId, clientSecret } = headers;
    const { phoneNumber, email, medium } = body;

    const url = `${config.baseUrl}/v1/auth/partner/otp/generate`;
    return this.post<GeneratePartnerOTPResponse>({
      url: url,
      body: {
        phoneNumber,
        email,
        medium,
      },
      headers: {
        [Header.ClientId]: clientId,
        [Header.ClientSecret]: clientSecret,
      },
      options: options,
    });
  }

  async verifyPartnerOTP(
    headers: {
      clientId: string;
      clientSecret: string;
    },
    body: GeneratePartnerOTPBody,
    options?: FetchOptions
  ): Promise<VerifyPartnerOTPResponse> {
    const { clientId, clientSecret } = headers;
    const { phoneNumber, email, otp, otpToken } = body;

    const url = `${config.baseUrl}/v1/auth/partner/otp/verify`;
    return this.post<VerifyPartnerOTPResponse>({
      url: url,
      body: {
        phoneNumber,
        email,
        otp,
        otpToken,
      },
      headers: {
        [Header.ClientId]: clientId,
        [Header.ClientSecret]: clientSecret,
      },
      options: options,
    });
  }

  async getCoinTransactions(
    headers?: Record<string, string>
  ): Promise<CoinsTransactionsResponse> {
    const path = `v1/coins/transactions`;
    return this.get<CoinsTransactionsResponse>({
      url: `${config.baseUrl}/${path}`,
      headers: headers,
    });
  }

  async logout() {
    const url = new URL(`${config.baseUrl}/v1/auth/logout`);
    return this.post<void>({
      url: url.toString(),
      body: {},
    });
  }

  async sendOtpForGiftBox(params: {
    id: string;
    deviceVerificationToken: string;
    identifier?: string;
    authMethod?: GiftAuthMethod | null;
  }): Promise<GiftSendOtpResponse> {
    if (params.id.includes("mock")) {
      console.table({
        id: params.id,
        identifier: params.identifier,
        authMethod: params.authMethod,
        endpoint: `/v2/gifting/gift-box/${params.id}/auth/send-otp`,
        deviceVerificationToken: params.deviceVerificationToken,
      });
      await new Promise((resolve) => setTimeout(resolve, 4000));
      return {
        otpToken: "otp-token",
      };
    } else {
      const path = `v2/gifting/gift-box/${params.id}/auth/send-otp`;
      return this.post<GiftSendOtpResponse>({
        url: `${config.baseUrl}/${path}`,
        body: {
          identifier: params.identifier,
          authMethod: params.authMethod || "PHONE_OTP",
        },
        headers: {
          [Header.FpDeviceToken]: params.deviceVerificationToken,
        },
      });
    }
  }

  async openGiftBox(params: {
    id: string;
    deviceVerificationToken: string;
    otp?: string;
    otpToken?: string;
  }): Promise<GiftVoucherDetailsResponse> {
    if (params.id.includes("mock")) {
      return getMockGetGiftVoucherDetailsResponse(params.id);
    }

    const path = `v2/gifting/gift-box/${params.id}/open`;
    return this.post<GiftVoucherDetailsResponse>({
      url: `${config.baseUrl}/${path}`,
      body: {
        otp: params.otp,
        otpToken: params.otpToken,
      },
      headers: {
        [Header.FpDeviceToken]: params.deviceVerificationToken,
      },
    });
  }

  async retryGiftBoxWhatsappDelivery(
    id: string,
    deviceVerificationToken: string
  ): Promise<GiftBoxDeliveryResponse> {
    if (id.includes("mock")) {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      return {
        retryAvailable: false,
      };
    }

    const path = `v2/gifting/gift-box/${id}/retry-delivery`;
    return this.post<GiftBoxDeliveryResponse>({
      url: `${config.baseUrl}/${path}`,
      body: {},
      headers: {
        [Header.FpDeviceToken]: deviceVerificationToken,
      },
    });
  }

  async getGiftDetailsMinimalResponse(
    key: string,
    deviceVerificationToken?: string
  ): Promise<GiftDetailsMinimalResponse> {
    if (key.includes("mock")) {
      console.table({
        key,
        deviceVerificationToken,
        endpoint: `/v2/gifting/gift-box/${key}`,
      });
      return await getMockGiftDetailsMinimalResponse(key);
    }
    const url = `${config.baseUrl}/v2/gifting/gift-box/${key}`;
    return this.get<GiftDetailsMinimalResponse>({
      url: url,
      headers: {
        [Header.FpDeviceToken]: deviceVerificationToken ?? "",
      },
    });
  }

  async applyGiftCard(
    cardNumber: string,
    clientId?: string
  ): Promise<RedeemCodeResponse> {
    const path = `v1/coins/gift-card/apply`;

    const headers: Record<string, string> = {};
    if (clientId) {
      headers[Header.ClientId] = clientId;
    }

    return this.post<RedeemCodeResponse>({
      url: `${config.baseUrl}/${path}`,
      body: { cardNumber: cardNumber },
      headers: headers,
    });
  }

  async getUserProfile(): Promise<UserProfile> {
    const url = `${config.baseUrl}/v1/user/profile`;
    return this.get<UserProfile>({
      url: url,
    });
  }

  async getCoinsSummary(
    headers?: Record<string, string>
  ): Promise<CoinsSummaryResponse> {
    const path = "v1/coins/summary";
    return this.get<CoinsSummaryResponse>({
      url: `${config.baseUrl}/${path}`,
      headers: headers,
    });
  }

  async getGiftBoxV3(
    key: string,
    headers?: Record<string, string>,
    options?: FetchOptions
  ): Promise<GetGiftBoxV3Response> {
    const path = `v3/gifting/gift/${key}`;
    return this.get<GetGiftBoxV3Response>({
      url: `${config.baseUrl}/${path}`,
      headers: headers,
      options: options,
    });
  }

  async sendGiftOtpV3(params: {
    id: string;
    identifier?: string;
    headers?: Record<string, string>;
  }): Promise<GiftSendOtpResponse> {
    const path = `v3/gifting/gift/${params.id}/auth/send-otp`;
    return this.post<GiftSendOtpResponse>({
      url: `${config.baseUrl}/${path}`,
      body: {
        identifier: params.identifier,
        authMethod: "PHONE_OTP",
      },
      headers: params.headers,
    });
  }

  async verifyGiftOtpV3(params: {
    id: string;
    otp: string;
    otpToken: string;
    headers?: Record<string, string>;
  }): Promise<GiftVerifyOtpResponseV3> {
    const path = `v3/gifting/gift/${params.id}/auth/verify-otp`;
    return this.post<GiftVerifyOtpResponseV3>({
      url: `${config.baseUrl}/${path}`,
      body: {
        otp: params.otp,
        otpToken: params.otpToken,
      },
      headers: params.headers,
    });
  }

  async getGiftMetaData(key: string): Promise<GiftBoxMetadata> {
    const path = `v3/gifting/gift/${key}/metadata`;
    return this.get<GiftBoxMetadata>({
      url: `${config.baseUrl}/${path}`,
    });
  }

  async simulateCart(
    payload: SquidSimulateProps
  ): Promise<SquidSimulateResponse> {
    const path = "v2/procurement/orders/simulate";
    return this.post<SquidSimulateResponse>({
      url: `${config.baseUrl}/${path}`,
      body: payload,
    });
  }

  async initiateSquidOrder(
    props: SquidOrderInitiateRequest
  ): Promise<SquidOrderInitiateResponse> {
    const path = "v2/procurement/orders/initiate";
    return this.post<SquidOrderInitiateResponse>({
      url: `${config.baseUrl}/${path}`,
      body: props,
    });
  }

  async getSquidOrderDetail(props: {
    orderId: string;
  }): Promise<SquidOrderDetailResponse> {
    const path = "v1/procurement/orders/" + props.orderId;
    return this.get<SquidOrderDetailResponse>({
      url: `${config.baseUrl}/${path}`,
    });
  }

  async getSquidWalletBalance(): Promise<{
    balance: number;
  }> {
    const path = "v1/procurement/wallet/balance";
    return this.get<{ balance: number }>({
      url: `${config.baseUrl}/${path}`,
    });
  }

  async downloadSquidOrder(orderId: string): Promise<any> {
    const path = `v1/procurement/orders/${orderId}/download/excel`;
    const headers: Record<string, string> = {};
    return this.get<any>({
      url: `${config.baseUrl}/${path}`,
      headers: headers,
      responseTypeNonJson: true,
    });
  }

  async getBrandExtraMetadata(
    brandIdOrBrandKey: string,
    headers?: Record<string, string>
  ): Promise<BrandExtraMetadata> {
    const url = `${config.baseUrl}/v1/store/products/voucher-product/${brandIdOrBrandKey}/extra-metadata`;
    return this.get<BrandExtraMetadata>({
      url: url,
      headers: headers,
    });
  }

  async updatePartnerUserProfile(
    body: {
      firstName?: string;
      lastName?: string;
      email?: Optional<string>;
      phoneNumber?: Optional<string>;
    },
    headers?: Record<string, string>
  ): Promise<any> {
    const url = `${config.baseUrl}/v1/user/partner/profile`;
    return this.patch<any>({
      url: url,
      body: body,
      headers: headers,
    });
  }

  async getMinimalClientConfigByPortal(
    host: string
  ): Promise<ClientMinimalConfig> {
    const url = `${config.baseUrl}/v1/client/config/minimal/by-portal-url/${host}`;
    return this.get<ClientMinimalConfig>({
      url: url,
    });
  }

  async createConversation(options?: FetchOptions): Promise<ChatDetails> {
    const url = `${config.baseUrl}/v1/pluto/conversation`;
    return this.post<ChatDetails>({
      url: url,
      body: {},
      options: options,
    });
  }

  async sendNewMessage(
    id: string,
    message: string,
    options?: FetchOptions
  ): Promise<ChatDetails> {
    const url = `${config.baseUrl}/v1/pluto/conversation/${id}/chat`;
    return this.post<ChatDetails>({
      url: url,
      body: { message },
      options: options,
    });
  }

  async getGift(id: string): Promise<GiftCardDetails> {
    const url = `${config.baseUrl}/v1/pluto/conversation/${id}/gift`;
    return this.get<GiftCardDetails>({
      url: url,
    });
  }

  async getPlutoCredits(): Promise<Credits> {
    const url = `${config.baseUrl}/v1/pluto/credits`;
    return this.get<Credits>({
      url: url,
    });
  }

  async updateGiftOccasion(
    id: string,
    { occasionTitle, recipientName, wishMessage }: GiftCardOccasion
  ): Promise<{ status: number }> {
    const url = `${config.baseUrl}/v1/pluto/gifts/${id}/occasion`;
    return this.put<{ status: number }>({
      url: url,
      body: {
        occasionTitle,
        recipientName,
        wishMessage,
      },
    });
  }

  async updateGiftSelection(
    id: string,
    selection: GiftCardSelection
  ): Promise<{ status: number }> {
    const url = `${config.baseUrl}/v1/pluto/gifts/${id}/selection`;
    return this.put<{ status: number }>({
      url: url,
      body: selection,
    });
  }

  async updateGiftBudget(
    id: string,
    budget: number
  ): Promise<{ status: number }> {
    const url = `${config.baseUrl}/v1/pluto/gifts/${id}/budget`;
    return this.put<{ status: number }>({
      url: url,
      body: { budget },
    });
  }

  async initiatePayment(id: string): Promise<InitiatePayment> {
    const url = `${config.baseUrl}/v1/pluto/gifts/${id}/pay`;
    return this.post<InitiatePayment>({
      url: url,
      body: {},
    });
  }

  async getGiftById(id: string): Promise<GiftCardDetails> {
    const url = `${config.baseUrl}/v1/pluto/gifts/${id}`;
    return this.get<GiftCardDetails>({
      url: url,
    });
  }

  async getAllConversations(): Promise<ChatDetails[]> {
    const url = `${config.baseUrl}/v1/pluto/conversation`;
    return this.get<ChatDetails[]>({
      url: url,
    });
  }

  async getAllGifts(): Promise<Gifts[]> {
    const url = `${config.baseUrl}/v1/pluto/gifts`;
    return this.get<Gifts[]>({
      url: url,
    });
  }

  async getConversationById(id: string): Promise<ChatDetails> {
    const url = `${config.baseUrl}/v1/pluto/conversation/${id}`;
    return this.get<ChatDetails>({
      url: url,
    });
  }

  async submitPlutoFeedback(
    id: string,
    rating: number,
    comment: string
  ): Promise<{ status: number }> {
    const url = `${config.baseUrl}/v1/pluto/gifts/${id}/rate`;
    return this.post<{ status: number }>({
      url: url,
      body: { rating, comment },
      responseTypeNonJson: true,
    });
  }

  async getShareableGiftLink(id: string): Promise<{ link: string }> {
    const url = `${config.baseUrl}/v1/pluto/gifts/${id}/share`;
    return this.get<{ link: string }>({
      url: url,
    });
  }

  async updateUserProfile(
    body: {
      firstName: string;
      lastName: string;
      phoneNumber: string;
    },
    headers?: Record<string, string>
  ): Promise<any> {
    const url = `${config.baseUrl}/v1/user/profile`;
    return this.patch<any>({
      url: url,
      body: body,
      headers: headers,
    });
  }

  async squidOnboarding(params: {
    emailId: string;
    name: string;
    phoneNumber: string;
    businessName: string;
    businessDetails?: {
      gstNumber?: string;
      address?: {
        address1?: string;
        address2?: string;
        place?: string;
        state?: string;
        pincode?: string;
        country?: string;
      };
      gstCertificateUrl?: string;
    };
  }): Promise<SquidOnboarding> {
    const url = `${config.baseUrl}/v1/squid/onboard`;
    return this.post<SquidOnboarding>({
      url: url,
      body: params,
    });
  }

  async verifySquidOnboarding(props: {
    id: string;
    params: {
      emailId: string;
      emailVerificationToken: string;
      emailOtp: string;
      phoneNumber: string;
      phoneVerificationToken: string;
      phoneOtp: string;
    };
  }): Promise<VerifySquidOnboardingResponse> {
    const url = `${config.baseUrl}/v1/squid/${props.id}/verify`;
    return this.post<VerifySquidOnboardingResponse>({
      url: url,
      body: props.params,
    });
  }

  async getSignedUrl(params: {
    contentType: string;
  }): Promise<{ url: string }> {
    const url = `${config.baseUrl}/v1/upload/signed-url`;
    return this.post<{ url: string }>({
      url: url,
      body: {
        purpose: "BUSINESS_DOCUMENT",
        contentType: params.contentType,
      },
    });
  }

  async getProcurementProfileData(): Promise<any> {
    const path = "v1/squid";
    return this.get<any>({
      url: `${config.baseUrl}/${path}`,
    });
  }

  async generateProformaInvoice(
    params: SquidProformaInvoiceGenerationProps
  ): Promise<SquidProformaInvoiceGenerationResponse> {
    const path = "v2/procurement/proforma-invoice/generate";
    return this.post<SquidProformaInvoiceGenerationResponse>({
      url: `${config.baseUrl}/${path}`,
      body: params,
    });
  }

  async sendPIOverEmail(props: { piID: string }) {
    const path = "v2/procurement/proforma-invoice/" + props.piID + "/send";
    return this.post<void>({
      url: `${config.baseUrl}/${path}`,
      body: {},
    });
  }

  async sendInvoiceOverEmail(props: { purchaseId: string }) {
    const path = "v2/procurement/invoice/" + props.purchaseId + "/send";
    return this.post<void>({
      url: `${config.baseUrl}/${path}`,
      body: {},
    });
  }

  async generateProcurementOtp(props: {
    phone?: string;
    email?: string;
  }): Promise<GenerateProcurementOTPResponse> {
    const path = "v1/auth/squid/otp/generate";
    return this.post<GenerateProcurementOTPResponse>({
      url: `${config.baseUrl}/${path}`,
      body: props,
    });
  }

  async getPlutoGiftPublic(giftId: string): Promise<PlutoGiftDetails> {
    const url = `${config.baseUrl}/public/pluto/gifts/${giftId}`;
    return this.get<PlutoGiftDetails>({
      url: url,
    });
  }

  async getPartnerCampaignDetail(props: { transactionId: string }) {
    const url = `${config.baseUrl}/partner/ketto/campaign-details?redemptionTransactionId=${props.transactionId}`;
    return this.get<any>({
      url: url,
    });
  }

  async updateSmartGCBrandSelection(
    id: string,
    brandKeys: string[]
  ): Promise<{ status: number }> {
    const url = `${config.baseUrl}/v1/smart-greeting-card/curation/${id}/brands`;
    return this.put<{ status: number }>({
      url: url,
      body: {
        brandKeys: brandKeys,
      },
    });
  }

  async updateSmartGCBudget(
    id: string,
    budget: number
  ): Promise<{ status: number }> {
    const url = `${config.baseUrl}/v1/smart-greeting-card/curation/${id}/budget`;
    return this.put<{ status: number }>({
      url: url,
      body: { budget },
    });
  }

  async updateSmartGCSenderName(
    id: string,
    name: string
  ): Promise<{ status: number }> {
    const url = `${config.baseUrl}/v1/smart-greeting-card/curation/${id}/sender-details`;
    return this.put<{ status: number }>({
      url: url,
      body: { name },
    });
  }

  async updateSmartGCWishMessage(
    id: string,
    wishMessage: string
  ): Promise<{ status: number }> {
    const url = `${config.baseUrl}/v1/smart-greeting-card/curation/${id}/wish-message`;
    return this.put<{ status: number }>({
      url: url,
      body: { wishMessage },
    });
  }

  async initiateSmartGCPayment(id: string): Promise<InitiatePayment> {
    const url = `${config.baseUrl}/v1/smart-greeting-card/curation/${id}/pay`;
    return this.post<InitiatePayment>({
      url: url,
      body: {},
    });
  }

  async getUserConfig(): Promise<Record<string, any>> {
    const url = `${config.baseUrl}/v1/user/config`;
    return this.get<Record<string, any>>({
      url: url,
    });
  }

  async updateUserConfig(body: Record<string, any>) {
    const url = `${config.baseUrl}/v1/user/config`;
    return this.patch<void>({
      url: url,
      body: body,
    });
  }

  async getShareableCoupon(
    headers?: Record<string, string>,
    options?: FetchOptions
  ): Promise<ShareableCoupon | null> {
    const url = `${config.baseUrl}/v1/coupons/hubble-promotion`;
    return this.get<ShareableCoupon | null>({
      url: url,
      headers: headers,
      options: options,
    });
  }

  async updateBusinessAddress(addressId: string) {
    const url = `${config.baseUrl}/v1/squid/details/default-address/${addressId}`;
    return this.post<void>({
      url: url,
      body: {},
    });
  }
}
